import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';
import { FormValidationService } from 'src/app/services/form-validation/form-validation.service';

@Component({
  selector: 'bt-inventory-image-section',
  templateUrl: './inventory-image-section.component.html',
  styleUrls: ['./inventory-image-section.component.scss'],
})
export class InventoryImageSectionComponent implements OnInit {

  @Input() itemData;
  inventoryItem: FormGroup;
  @Output() delete = new EventEmitter();
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private commonServices: CommonServices,
    public formValidationService: FormValidationService,
    public crudService: CrudService
  ) {

  }

  ngOnInit() {
    /* console.log(this.itemData) */
  }


  uploadFile(file, itemData) {
    this.isLoading = true;
    let id = itemData.get('id').value;
    let path = 'MASTER_TEMPLATES/inventory/' + this.itemData.get('subCategoryId').value;
    const formData = this.commonServices.formData(file[0], path);
    this.crudService.uploadFile(formData).subscribe(folderResp => {
      let filesItem = this.commonServices.uploadFile(folderResp, this.itemData.get('subCategoryId').value, null)
      filesItem.path = path;
      let filesItems = [];
      filesItems.push(filesItem);
      itemData.get('attachment').setValue(filesItems);
      this.isLoading = false;
    });
  }

  deleteChoice() {
    this.delete.emit()
  }

}
