import { Component, Inject, OnInit } from '@angular/core';
import { FormValidationService } from 'src/app/services/form-validation/form-validation.service';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as firebase from 'firebase/app';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { AuthServices } from 'src/app/services/auth-services/auth-services.service';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { attributeList } from 'src/app/attribute-list/attribute-list';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {


  firebase = firebase.default;
  teamForm: FormGroup;
  attributeList = attributeList;
  selectedUser;

  constructor(
    public formValidationService: FormValidationService,
    public formBuilder: FormBuilder,
    public commonServices: CommonServices,
    public crudService: CrudService,
    public authServices: AuthServices,
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {

    this.selectedUser = this.data.selectedUser;

    this.teamForm = this.formBuilder.group({
      id: [''],
      uid: [''],
      createdBy: [''],
      createdAt: [''],
      updatedBy: [''],
      updatedAt: [''],
      deleteFlag: [''],
      isVerify: [true],
      email: ['', Validators.compose([Validators.maxLength(70), Validators.email, Validators.required])],
      first_name: ['', Validators.compose([Validators.required])],
      last_name: ['', Validators.compose([Validators.required])],
      company_id: [''],
      /* contact: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")])], */
      address: [''],
      user_role: ['', Validators.compose([Validators.required])],
      status: ['', Validators.compose([Validators.required])],
      user_type: [''],
      isClient: [''],
      hourlyRate: [''],
      internal_user: [true],
      allProjectAccess: [false],
    });

  }

  ngOnInit() {
    this.teamForm.get('status').setValue(attributeList.user_status[0]);
    this.teamForm.get('user_role').setValue(this.data.userTypeList[0]);
    this.teamForm.get('user_type').setValue(this.teamForm.get('user_role').value);

    setTimeout(() => {
      if (this.selectedUser) {
        this.teamForm.patchValue(this.selectedUser);
      }
    }, 450)
  }

  setRole(event) {
    this.teamForm.get('user_type').setValue(event);
  }


  createUser() {
    if (this.teamForm.valid) {
      let email = this.teamForm.value.email
      let password = this.commonServices.generatePassword();

      if (!this.teamForm.value.id) {

        this.teamForm.get('isClient').setValue(false);
        let { secondaryApp, auth } = this.authServices.registerTeamAndClient(email, password);
        auth.then((resp) => {
          secondaryApp.auth().signOut();
          this.teamForm.get('uid').setValue(resp.user.uid)
          this.commonServices.dismissLoading();
          this.crudService.addWithCollName('user', this.teamForm.value, resp.user.uid).then(rep => {
            //this.presentAlertPrompt(password);
            console.log(password);
            console.log();

            this.authServices.tokenUpdate().then(res => {
              window.localStorage.setItem('token', res.token);
              this.crudService.auth(null, resp.user.uid).subscribe(resp => {
                console.log(resp);
              })
            });



            secondaryApp.delete();
            //this.commonServices.registrationEmail(this.teamForm.value, this.company, password);
            this.teamForm.value.id = resp.user.uid;
            this.dismiss(this.teamForm.value);
            this.reset();
          }).catch(error => {
            secondaryApp.delete();
            secondaryApp.auth().currentUser.delete();
            secondaryApp.auth().signOut();
          });

        }).catch(error => {
          secondaryApp.delete();

          this.commonServices.alertPopup('Error', error.message);
        });;
      }
      else {
        this.crudService.updateWithCollNamer('user', this.teamForm.value, this.teamForm.value.id).then(resp => {
          this.dismiss(this.teamForm.value);
          this.teamForm.reset();
        }).catch(error => {

        });
      }

    }
    else {
      this.teamForm.markAllAsTouched();
    }
  }

  reset() {
    this.teamForm.reset();
    this.teamForm.get('user_type').setValue(attributeList.user_type[0]);
    this.teamForm.get('status').setValue(attributeList.user_status[0]);
    this.teamForm.get('user_role').setValue(attributeList.user_role[0]);
  }

  dismiss(user) {
    this.dialogRef.close(user);
  }

}
