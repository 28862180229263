import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bt-inclusion-template-name',
  templateUrl: './inclusion-template-name.component.html',
  styleUrls: ['./inclusion-template-name.component.scss'],
})
export class InclusionTemplateNameComponent implements OnInit {

  templateName;
  constructor(
    public dialogRef: MatDialogRef<InclusionTemplateNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.templateName = this.data;
  }

}
