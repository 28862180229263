import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';



/* Components */


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgScrollbarModule } from 'ngx-scrollbar';


import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';

import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';


import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';



import { ColorPickerModule } from 'ngx-color-picker';
import { IonicModule } from '@ionic/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InclusionCategoryCreateComponent } from './components/inclusion-category-create/inclusion-category-create.component';
import { InclusionSelectViewComponent } from './components/inclusion-select-view/inclusion-select-view.component';
import { InclusionTemplateNameComponent } from './components/inclusion-template-name/inclusion-template-name.component';
import { InclusionCategoryListComponent } from './components/inclusion-category-list/inclusion-category-list.component';
import { InclusionStepperComponent } from './components/inclusion-stepper/inclusion-stepper.component';
import { LightboxModule } from 'ngx-lightbox';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TaskSectionComponent } from './components/task-section/task-section.component';
import { StageSectionComponent } from './components/stage-section/stage-section.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { AddSequenceTaskComponent } from './components/add-sequence-task/add-sequence-task.component';
import { AddCategoryModelComponent } from './components/add-category-model/add-category-model.component';
import { InventoryImageSectionComponent } from './components/inventory-image-section/inventory-image-section.component';
import { ManageInventoryViewComponent } from './components/manage-inventory-view/manage-inventory-view.component';
import { InclusionTemplateComponent } from './components/inclusion-template/inclusion-template.component';
import { AddTaskComponent } from './components/add-task/add-task.component';
import { BudgetLineItemComponent } from './components/budget-components/budget-line-item/budget-line-item.component';
import { BudgetLineItemHeaderComponent } from './components/budget-components/budget-line-item-header/budget-line-item-header.component';
import { AddUpdateSingleNameComponent } from './components/model-components/add-update-single-name/add-update-single-name.component';
import { ProjectStatusComponent } from './components/project-setup/project-status/project-status.component';
import { UserTypeSelectboxComponent } from './components/user-type-selectbox/user-type-selectbox.component';
import { AddUserComponent } from './components/add-user/add-user.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgScrollbarModule,
        MatDialogModule,

        MatFormFieldModule,
        MatSelectModule,
        DragDropModule,
        MatMenuModule,
        MatChipsModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        ClipboardModule,
        MatSnackBarModule,
        MatTabsModule,
        MatIconModule,
        MatCheckboxModule,
        MatPaginatorModule,

        ColorPickerModule,
        IonicModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ScrollingModule,
        LightboxModule,
        CKEditorModule,
        MatStepperModule,
        MatButtonToggleModule,

    ],
    declarations: [
        AddCategoryModelComponent,
        InclusionCategoryCreateComponent,
        InclusionSelectViewComponent,
        InclusionTemplateNameComponent,
        InclusionCategoryListComponent,
        InclusionStepperComponent,
        TaskSectionComponent,
        StageSectionComponent,
        SortByPipe,
        AddSequenceTaskComponent,
        InventoryImageSectionComponent,
        ManageInventoryViewComponent,
        InclusionTemplateComponent,
        AddTaskComponent,
        BudgetLineItemComponent,
        BudgetLineItemHeaderComponent,
        AddUpdateSingleNameComponent,
        ProjectStatusComponent,
        UserTypeSelectboxComponent,
        AddUserComponent
    ],
    exports: [
        ProjectStatusComponent,
        AddUpdateSingleNameComponent,
        BudgetLineItemHeaderComponent,
        BudgetLineItemComponent,
        AddTaskComponent,
        AddSequenceTaskComponent,
        SortByPipe,
        StageSectionComponent,
        MatButtonToggleModule,
        MatStepperModule,
        InclusionStepperComponent,
        InclusionCategoryCreateComponent,
        InclusionCategoryListComponent,
        InclusionSelectViewComponent,
        InclusionTemplateNameComponent,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TaskSectionComponent,
        NgScrollbarModule,
        AddCategoryModelComponent,
        MatDialogModule,
        InventoryImageSectionComponent,
        ManageInventoryViewComponent,
        MatFormFieldModule,
        MatSelectModule,
        DragDropModule,
        MatMenuModule,

        MatChipsModule,
        MatInputModule,
        MatButtonModule,

        MatTableModule,
        ClipboardModule,
        MatSnackBarModule,
        MatTabsModule,
        MatIconModule,

        MatCheckboxModule,

        MatPaginatorModule,

        ColorPickerModule,
        IonicModule,

        MatDatepickerModule,
        MatNativeDateModule,

        MatCardModule,
        MatProgressSpinnerModule,

        MatTooltipModule,

        ScrollingModule,
        LightboxModule,
        CKEditorModule,
        InclusionTemplateComponent,
        UserTypeSelectboxComponent,

        AddUserComponent
    ],
    providers: [

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SharedModule { }