import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  constructor() { }

  requiredFieldValidate(formBuilder: FormGroup, fieldName: string) {
    return formBuilder.get(fieldName).hasError('required')
  }

  emailFiedlValidation(formBuilder: FormGroup, fieldName: string) {
    return formBuilder.get(fieldName).hasError('email')
  }

  isFieldError(formBuilder: FormGroup, fieldName: string) {
    return formBuilder.get(fieldName).invalid && formBuilder.get(fieldName).errors && (formBuilder.get(fieldName).dirty || formBuilder.get(fieldName).touched)
  }
}
