import { FilesItem } from "./file";

export class Task {
    id: string;
    company_id: string;
    project_id: string;
    taskList: Array<TaskList> = [];
    parallelTaskList: Array<TaskList> = [];
    stageList: Array<StageList> = [];
    updatedAt: string;
    deleteFlag: string;
    createdBy: string;
    updatedBy: string;
    templateName?: string;
}


export class TaskList {
    id: string;
    type: string;
    stage: any;
    deleteFlag: string;
    /* Task Name */
    title: string;
    /* TASK DESCRIPTION */
    description: string;
    /* ASSIGNED To */
    actionBy: any;
    /* ACTION TYPE */
    action: any;
    /* NOTIFICATION TEMPLATE  */
    selectedTemplate: any;
    /* DURATION */
    taskDuration: number;
    position: number;
    task_status: any; // COMPLETED, IN_PROGRESS, CANCLED
    files: Array<FilesItem> = [];
    parentTask?: string;
    purchaseItem: boolean;
    purchaseOrder: boolean;
    /* START DATE */
    startDate?: string;
    endDate?: string;
    purchaseReminders?: string;
    createdDate: string;
    categoryItemId?: string;
    itemId?: string;

    dependency: boolean = false;
    displayColor;

}

export class StageList {
    id: string;
    deleteFlag: string;
    stage_name: string;
    taskList?: Array<TaskList> = [];
    isOpen?: boolean = false;
}