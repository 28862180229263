import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthServices } from './services/auth-services/auth-services.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Project Payments', url: '/home', icon: 'wallet-outline' },
    { title: 'Doc List', url: '/doc-list', icon: 'document-text-outline' },
    { title: 'Document Folders', url: '/file-tags', icon: 'folder-open-outline' },
    { title: 'Project Budget', url: '/project-budget', icon: 'card-outline' },
    { title: 'Contractors Suppliers Tags', url: '/contractors-suppliers-tags', icon: 'people-outline' },
    { title: 'Inclusion List', url: '/inclusion-list', icon: 'list-outline' },
    { title: 'Task Template', url: '/build-section', icon: 'file-tray-full-outline' },
    { title: 'Notification Template', url: '/notification-template', icon: 'notifications-outline' },
    { title: 'Reset Section', url: '/reset-section', icon: 'refresh' },
    { title: 'Quotation Template', url: '/quotation-template', icon: 'cash-outline' },
    { title: 'Project Setup', url: '/project-setup', icon: 'options-outline' },
    { title: 'Rules Setup', url: '/user-access', icon: 'options-outline' },
    { title: 'Measures List', url: '/measures-list', icon: 'list-circle-outline' },
    { title: 'Recipe', url: '/recipe', icon: 'list-circle-outline' },
    { title: 'User Type', url: '/user-type', icon: 'people' },
  ];
  userData;
  constructor(
    private authServices: AuthServices,
    private router: Router,
    private menuController: MenuController,
  ) {
    this.authServices.checkSession().subscribe(resp => {
      this.userData = resp;
    })

  }

  logout() {
    this.authServices.logout().then(resp => {
      this.menuController.enable(false)
      this.router.navigateByUrl('login');
    });
  }
}
