export const messages = {
    TRY_LATTER: 'Something went wrong please try latter',
    USER_PASS_WRONG: 'Username and password is wrong',
    POPUP_CLOSE: 'The popup has been closed Login fail',
    USER_ALREADY_REGISTER: 'This email already exists',
    MOBILE_USED: 'Mobile already used',
    CHECK_DATA: 'Something went wrong please check Details',
    DETAIL_ADDED: 'Detail Added successfully',
    DETAIL_UPDATED: 'Detail updated successfully',
    INVOICE_CREATED: 'Invoice Created successfully',
    PROJECT_CREATED: 'Project Created successfully',
    PERMISSION_UPDATE: "You donn't have permission to update",
    PLEASE_FILL: 'Some Details are missing please fill',
    CREATED: 'Created successfully',
    ADDED: 'Added successfully',
    ACCESS_DENIED: "You don't have permission to access",
    MISSING: "is missing",
    NOT_ACTIVE: 'is not active'

}