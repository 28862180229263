import { TagItem } from "./file-tag";

export class FileMain {
    id: string;
    project_id: string;
    company_id: string;
    folderPath: string;
    files: Array<FilesItem> = [];
    folders: Array<Folder> = [];
    folderPublicURL: string;
    folder_id: string;
    role: any;
    updatedAt: string;
    deleteFlag: string;
    createdBy: string;
    updatedBy: string;
    folderName?: string;
}

export class FilesItem {
    id: string;
    path: string;
    fileName: string;
    publicURL: string;
    fileType: string;
    size: number;
    tagItem: Array<TagItem> = [];
    lastModifiedDateTime: string;
    parentFolder: string;
    thumbnail: any;
    folderPublicURL: string;
    deleteFlag: string = "N";

    itemName?: string;
    cost?: number;
    printNumber?: number;
}

export class Folder {
    id: string;
    path: string;
    folderName: string;
    publicURL: string;
    files: Array<FilesItem> = []

}