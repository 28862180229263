import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inclusions } from 'src/app/model/company/inclusion';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';
import { FormValidationService } from 'src/app/services/form-validation/form-validation.service';

import { FilesItem } from 'src/app/model/project/file';

@Component({
  selector: 'bt-inclusion-template',
  templateUrl: './inclusion-template.component.html',
  styleUrls: ['./inclusion-template.component.scss', '../../pages/inclusion-create/inclusion-create.page.scss'],
})
export class InclusionTemplateComponent implements OnInit, OnChanges {

  @Output() formValidate = new EventEmitter<any>();


  /* inclusionForm: any; */
  @Input() company;
  @Input() selectedInclusionId;
  @Input() inclusionViewMode;
  @Input() templateName: string;


  @Input() selectedInclusion;
  @Input() project_id;

  inclusionCreateList: Inclusions;
  inclusionName: string = "Classic";

  constructor(
    private formBuilder: FormBuilder,
    private commonServices: CommonServices,
    private crudService: CrudService,
    private formValidationService: FormValidationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    console.log(this.selectedInclusionId)
  }

  ngOnChanges() {
    /* this.cdr.detectChanges(); */
    if (this.selectedInclusion == undefined && this.company) {
      this.formCreate()
    }

    if (this.selectedInclusion) {
      if (this.templateName) {
        this.selectedInclusion.templateName = this.templateName;
      }

      if (this.selectedInclusionId) {
        this.selectedInclusion.id = this.selectedInclusionId;
      }
      /* if (this.project_id) {
        this.inclusionForm.get('project_id').setValue(this.project_id);
      } */
    }
  }


  formCreate() {
    if (this.project_id) {
      /* this.crudService.getWithTwoWhereNoTakeCompanyWithProjectIdOtherColl('inclusions', this.project_id).subscribe((resp: Array<Inclusions>) => {
        if (resp[0]) {
          this.selectedInclusion = resp[0];
          this.formValidate.emit(this.selectedInclusion)
        }
      }) */
    }
    /* else if (this.selectedInclusionId != undefined) {
      this.crudService.getNoTake(this.selectedInclusionId).subscribe(resp => {
        this.selectedInclusion = resp;
        this.formValidate.emit(this.selectedInclusion)
      });
    } */
  }


}
