import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGardService } from './services/authGard/auth-gard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'doc-list',
    loadChildren: () => import('./pages/doc-list/doc-list.module').then(m => m.DocListPageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'file-tags',
    loadChildren: () => import('./pages/file-tags/file-tags.module').then(m => m.FileTagsPageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'project-budget',
    loadChildren: () => import('./pages/project-budget/project-budget.module').then(m => m.ProjectBudgetPageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'contractors-suppliers-tags',
    loadChildren: () => import('./pages/contractors-suppliers-tags/contractors-suppliers-tags.module').then(m => m.ContractorsSuppliersTagsPageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'inclusion-list',
    loadChildren: () => import('./pages/inclusion-list/inclusion-list.module').then(m => m.InclusionListPageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'inclusion-create',
    loadChildren: () => import('./pages/inclusion-create/inclusion-create.module').then(m => m.InclusionCreatePageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'inclusion-create/:id',
    loadChildren: () => import('./pages/inclusion-create/inclusion-create.module').then(m => m.InclusionCreatePageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'inclusion-create/:id/:categoryId',
    loadChildren: () => import('./pages/inclusion-create/inclusion-create.module').then(m => m.InclusionCreatePageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'build-section',
    loadChildren: () => import('./pages/build-section/build-section.module').then(m => m.BuildSectionPageModule),
    canActivate: [AuthGardService]
  },
  {
    path: 'notification-template',
    loadChildren: () => import('./pages/notification-template/notification-template.module').then(m => m.NotificationTemplatePageModule)
  },
  {
    path: 'inventory-list',
    loadChildren: () => import('./pages/inventory-list/inventory-list.module').then(m => m.InventoryListPageModule)
  },
  {
    path: 'reset-section',
    loadChildren: () => import('./pages/reser-section/reser-section.module').then( m => m.ReserSectionPageModule)
  },
  {
    path: 'quotation-template',
    loadChildren: () => import('./pages/quotation-template/quotation-template.module').then( m => m.QuotationTemplatePageModule)
  },
  {
    path: 'project-setup',
    loadChildren: () => import('./pages/project-setup/project-setup.module').then( m => m.ProjectSetupPageModule)
  },
  {
    path: 'user-access',
    loadChildren: () => import('./pages/user-access/user-access.module').then( m => m.UserAccessPageModule)
  },
  {
    path: 'measures-list',
    loadChildren: () => import('./pages/measures-list/measures-list.module').then( m => m.MeasuresListPageModule)
  },
  {
    path: 'recipe',
    loadChildren: () => import('./pages/recipe/recipe.module').then( m => m.RecipePageModule)
  },
  {
    path: 'user-type',
    loadChildren: () => import('./pages/user-type/user-type.module').then( m => m.UserTypePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
