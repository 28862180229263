import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { messages } from 'src/app/messages-list/messages';
import { StageList, Task } from 'src/app/model/project/task';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';

@Component({
  selector: 'bt-stage-section',
  templateUrl: './stage-section.component.html',
  styleUrls: ['./stage-section.component.scss'],
})
export class StageSectionComponent implements OnInit {

  stageItem;
  task: Task;
  constructor(
    public dialogRef: MatDialogRef<StageSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private crudService: CrudService,
    private commonServices: CommonServices,
  ) {
    this.task = data.task;
    if (!this.task.stageList) {
      this.task.stageList = new Array<StageList>();
    }
  }

  ngOnInit() { }

  addStage(stageItem) {
    let stageList: StageList = new StageList();
    stageList.id = this.commonServices.getUniqueId();
    stageList.stage_name = stageItem;
    stageList.deleteFlag = 'N';
    this.task.stageList.push(stageList)
    this.stageItem = "";
    this.update();

  }

  delete(stage) {
    let index = this.task.stageList.findIndex(singleStage => singleStage.id == stage.id)
    this.task.stageList[index].deleteFlag = 'Y';
    this.update();
  }

  update() {
    if (this.stageItem) {
      this.addStage(this.stageItem)
    }
    this.crudService.updateWithCollNamer(this.data.collection, this.task, this.task.id).then(resp => {
      this.commonServices.snackBarOpen(messages.DETAIL_UPDATED)
      this.stageItem = "";
    });
  }

}
