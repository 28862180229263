import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Inclusions, Category, SubCategory } from 'src/app/model/company/inclusion';
import { MatDialog } from '@angular/material/dialog';
import { InclusionCategoryCreateComponent } from '../inclusion-category-create/inclusion-category-create.component';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { messages } from 'src/app/messages-list/messages';
import { EventService } from 'src/app/services/event/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bt-inclusion-select-view',
  templateUrl: './inclusion-select-view.component.html',
  styleUrls: ['./inclusion-select-view.component.scss'],
})
export class InclusionSelectViewComponent implements OnInit, OnChanges, OnDestroy {

  inclusionForm: any;
  @Input() company;
  @Input() selectedInclusionId;
  @Input() selectedCategoryId;
  @Input() openAdd;
  @Input() selectedInclusion;
  @Input() filterByStatusValue;
  @Input() categoryData: Array<{}>;
  inclusions: Inclusions;
  public collName = "MASTER_TEMPLATES";
  update_inclusion$: Subscription;
  openViewMode: boolean = false;
  constructor(
    public crudService: CrudService,
    public commonServices: CommonServices,
    public dialog: MatDialog,
    private eventService: EventService,
  ) { }

  ngOnInit() {
    /* if (this.company && this.selectedInclusionId) {
      this.getInclusion();
    } */
    this.update_inclusion$ = this.eventService.subscribe('update_inclusion', (data?) => {
      if (data) {
        this.updateCategory(data);
      }
    });
  }

  ngOnChanges() {

    if (this.selectedInclusionId && !this.inclusions) {
      this.getInclusion();
    }

    console.log(this.openAdd)

    if (this.openAdd) {
      this.addNewCategory();
    }

    if (this.selectedInclusion) {
      this.inclusions = this.selectedInclusion;
    }

  }

  getInclusion() {

    this.crudService.getWithWhereNoTake(this.collName, 'id', '==', this.selectedInclusionId).subscribe(resp => {
      this.inclusions = resp[0];
      if (this.selectedCategoryId && !this.openViewMode) {
        this.openViewMode = true;
      }
    }, error => {
      console.log(error)
    });
  }

  addNewCategory(category?: Category) {
    const dialogRef = this.dialog.open(InclusionCategoryCreateComponent, {
      width: '400px',
      data: category
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        /* let index = this.inclusions.categoryItem.findIndex(singleCategory => singleCategory.id == result.id)
        if (index == -1) {
          this.inclusions.categoryItem.push(result);
        }
        else {
          this.inclusions.categoryItem[index] = result;
        } */
        this.updateInclusionData(result);
        this.updateInclusion();
      }
    });

  }

  updateInclusion() {
    this.crudService.updateWithCollNamer(this.collName, this.inclusions, this.inclusions.id).then(resp => {
      this.commonServices.snackBarOpen(messages.DETAIL_UPDATED, 'success-snackbar')
    })
  }

  editCategory(event: Category) {
    this.addNewCategory(event)
    /* this.updateInclusionData(event);
    this.updateInclusion(); */
    /* this.addNewCategory(event) */
  }

  updateInclusionData(result) {
    console.log(this.inclusions)
    if (!this.inclusions) {
      this.inclusions = new Inclusions();
      this.inclusions.categoryItem = new Array<Category>();
    }
    else if (!this.inclusions.categoryItem) {
      this.inclusions.categoryItem = new Array<Category>();
    }
    let index = this.inclusions.categoryItem.findIndex(singleCategory => singleCategory.id == result.id)
    if (index == -1) {
      result.subCategory = new Array<SubCategory>();
      this.inclusions.categoryItem.push(result);
    }
    else {
      this.inclusions.categoryItem[index] = result;
    }
  }

  updateCategory(event) {
    console.log(event)
    this.updateInclusionData(event);
    this.updateInclusion();
  }

  ngOnDestroy() {
    this.update_inclusion$.unsubscribe();
  }

  checkSelectedCategoryId(category) {
    if (category.subCategory.findIndex(sub => sub.id == this.selectedCategoryId) != -1) {
      return this.selectedCategoryId;
    }
    return null;
  }

}
