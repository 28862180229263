export const attributeList = {
    client_status: [
        {
            id: '0',
            attribute_value: 'Saved',
            attribute_key: 'SAVED',
            color: 'medium'
        },
        {
            id: '1',
            attribute_value: 'Active',
            attribute_key: 'ACTIVE',
            color: 'primary'
        },
        {
            id: '2',
            attribute_value: 'Completed',
            attribute_key: 'COMPLETED',
            color: 'success'
        },
        {
            id: '3',
            attribute_value: 'Cancelled',
            attribute_key: 'CANCELLED',
            color: 'danger'
        }
    ],
    invoice_status: [
        {
            id: '6',
            attribute_value: 'Draft',
            attribute_key: 'DRAFT',
            color: 'medium'
        },
        {
            id: '2',
            attribute_value: 'Sent',
            attribute_key: 'SENT',
            color: 'secondary'
        },
        {
            id: '4',
            attribute_value: 'Approved',
            attribute_key: 'APPROVED',
            color: 'success'
        },
        {
            id: '5',
            attribute_value: 'Rejected',
            attribute_key: 'REJECTED',
            color: 'danger'
        },
        {
            id: '1',
            attribute_value: 'Invoiced',
            attribute_key: 'INVOICED',
            color: 'primary'
        },
        {
            id: '0',
            attribute_value: 'Pending',
            attribute_key: 'PENDING',
            color: 'medium'
        },
        {
            id: '3',
            attribute_value: 'Paid',
            attribute_key: 'PAID',
            color: 'success'
        },
    ],
    variation_status: [
        {
            id: '6',
            attribute_value: 'Draft',
            attribute_key: 'DRAFT',
            color: 'medium'
        },
        {
            id: '2',
            attribute_value: 'Sent',
            attribute_key: 'SENT',
            color: 'secondary'
        },
        {
            id: '4',
            attribute_value: 'Approved',
            attribute_key: 'APPROVED',
            color: 'success'
        },
        {
            id: '5',
            attribute_value: 'Rejected',
            attribute_key: 'REJECTED',
            color: 'danger'
        },
        {
            id: '1',
            attribute_value: 'Invoiced',
            attribute_key: 'INVOICED',
            color: 'primary'
        },
        {
            id: '0',
            attribute_value: 'Pending',
            attribute_key: 'PENDING',
            color: 'medium'
        },
        {
            id: '3',
            attribute_value: 'Paid',
            attribute_key: 'PAID',
            color: 'success'
        },
    ],
    user_type: [
        {
            id: '0',
            attribute_value: 'Company',
            attribute_key: 'COMPANY',
        },
        {
            id: '1',
            attribute_value: 'Customer',
            attribute_key: 'CUSTOMER',
        }
    ],
    document_status: [
        {
            id: '0',
            attribute_value: 'Saved',
            attribute_key: 'SAVED',
            color: 'medium'
        },
        {
            id: '1',
            attribute_value: 'Expired',
            attribute_key: 'EXPIRED',
            color: 'primary'
        },
        {
            id: '2',
            attribute_value: 'Sent',
            attribute_key: 'SENT',
            color: 'danger'
        },
        {
            id: '3',
            attribute_value: 'Completed',
            attribute_key: 'COMPLETED',
            color: 'success'
        }
    ],
    user_role: [
        {
            id: 0,
            attribute_value: 'Owner',
            attribute_key: 'MASTER',
        }
        ,
        {
            id: 1,
            attribute_value: 'Project Manager',
            attribute_key: 'PROJECTMANAGER',
        },
        {
            id: 2,
            attribute_value: 'Site superviser ',
            attribute_key: 'SITESUPERVISOR',
        },
        {
            id: 3,
            attribute_value: 'Employee',
            attribute_key: 'EMPLOYEE',
        },
        {
            id: 4,
            attribute_value: 'Client',
            attribute_key: 'CLIENT',
            checked: null
        },
        {
            id: 5,
            attribute_value: 'Accounts',
            attribute_key: 'ACCOUNTS',
            checked: null
        },
        {
            id: 6,
            attribute_value: 'Sales',
            attribute_key: 'SALES',
            checked: null
        },
        {
            id: "jqSvfCXOZearXdjwPugM",
            attribute_value: 'BM 1',
            attribute_key: 'BM1',
            checked: null
        },
        {
            id: "F0jXE6YLwmUGlMpv4il5",
            attribute_value: 'BM 2',
            attribute_key: 'BM2',
            checked: null
        },
        {
            id: "VctPdrYO4SZWI5Bp72JB",
            attribute_value: 'BM 3',
            attribute_key: 'BM3',
            checked: null
        }
    ],
    user_status: [
        {
            id: '0',
            attribute_value: 'Active',
            attribute_key: 'ACTIVE',
        },
        {
            id: '1',
            attribute_value: 'Deactive',
            attribute_key: 'DEACTIVE',
        }
    ],
    trade_type_contractors: [
        {
            id: '0',
            attribute_value: 'Electrician',
            attribute_key: 'ELECTRICIAN',
        },
        {
            id: '1',
            attribute_value: 'Plumber',
            attribute_key: 'PLUMBER',
        },
        {
            id: '2',
            attribute_value: 'Concretor',
            attribute_key: 'CONCRECTOR',
        },
        {
            id: '3',
            attribute_value: 'Certifier',
            attribute_key: 'CERTIFIER',
        },
        {
            id: '4',
            attribute_value: 'Surveryor',
            attribute_key: 'SURVERYOR',
        }
    ],
    trade_type_suppliers: [
        {
            id: '0',
            attribute_value: 'Electrician',
            attribute_key: 'ELECTRICIAN',
        },
        {
            id: '1',
            attribute_value: 'Plumber',
            attribute_key: 'PLUMBER',
        },
        {
            id: '2',
            attribute_value: 'Concretor',
            attribute_key: 'CONCRECTOR',
        },
        {
            id: '3',
            attribute_value: 'Certifier',
            attribute_key: 'CERTIFIER',
        },
        {
            id: '4',
            attribute_value: 'Surveryor',
            attribute_key: 'SURVERYOR',
        }
    ],
    project_type: [
        {
            id: '0',
            attribute_value: 'Single Story',
            attribute_key: 'SINGLE_STORY',
        },
        {
            id: '1',
            attribute_value: 'Double Story',
            attribute_key: 'DOUBLE_STORY',
        }
    ],
    project_status: [
        {
            id: '0',
            attribute_value: 'Active',
            attribute_key: 'IN_PROGRESS',
            color: 'var(--ion-color-primary)',
            background: '#7da7ef',
            icons: 'watch_later',
        },
        {
            id: '1',
            attribute_value: 'Completed',
            attribute_key: 'COMPLETED',
            color: '#42d77d',
            icons: 'check_circle',
            background: '#bbf7d0'
        },
        {
            id: '2',
            attribute_value: 'Canceled',
            attribute_key: 'CANCELED',
            color: '#cf3c4f',
            icons: 'delete_outline',
            background: '#fecaca'
        },
        {
            id: '3',
            attribute_value: 'Archived',
            attribute_key: 'ARCHIVED',
            color: '#1e293b',
            background: '#e2e8f0',
            icons: 'archive'
        },
    ],
    file_tag: [
        {
            id: '0',
            attribute_value: 'Invoice',
            attribute_key: 'INVOICE',
        },
        {
            id: '1',
            attribute_value: 'Inclusion list',
            attribute_key: 'INCLUSION_LIST',
        },
        {
            id: '2',
            attribute_value: 'Variation invoice',
            attribute_key: 'VARIATION_INVOICE',
        },
        {
            id: '3',
            attribute_value: 'Quotation',
            attribute_key: 'QUOTATION',
        },
        {
            id: '4',
            attribute_value: 'Order',
            attribute_key: 'ORDER',
        },
        {
            id: '5',
            attribute_value: 'BA',
            attribute_key: 'BA',
        },
        {
            id: '6',
            attribute_value: 'Client',
            attribute_key: 'CLIENT',
        }
    ],
    file_folder_list: [
        {
            id: '0',
            attribute_value: 'Project Docs',
            attribute_key: 'PROJECTS_DOCS',
        },
        {
            id: '1',
            attribute_value: 'Owner Only',
            attribute_key: 'OWNER_ONLY',
        },
        {
            id: '2',
            attribute_value: 'Client Only',
            attribute_key: 'CLIENT_ONLY',
        },
        {
            id: '3',
            attribute_value: 'Project Sensitive',
            attribute_key: 'PROJECTS_SENSITIVE',
        }
    ],
    colorList: [
        {
            id: 0,
            color: 'rgb(100,116,139)',
            background: 'rgb(228 228 228)'
        },
        {
            id: 1,
            color: 'rgb(239,68,68)',
            background: 'rgb(254, 226, 226)'
        },
        {
            id: 2,
            color: 'rgb(249,115,22)',
            background: 'rgb(255, 237, 213)'
        },
        {
            id: 3,
            color: 'rgb(234,179,8)',
            background: 'rgb(254, 249, 195)'
        },
        {
            id: 4,
            color: 'rgb(34,197,94)',
            background: 'rgb(220, 252, 231)'
        },
        {
            id: 5,
            color: 'rgb(20,184,166)',
            background: 'rgb(204, 251, 241)'
        },
        {
            id: 6,
            color: 'rgb(59,130,246)',
            background: 'rgb(219, 234, 254)'
        },
        {
            id: 7,
            color: 'rgb(99,102,241)',
            background: 'rgb(224, 231, 255)'
        },
        {
            id: 8,
            color: 'rgb(168,85,247)',
            background: 'rgb(243, 232, 255)'
        },
        {
            id: 9,
            color: 'rgb(236,72,153)',
            background: 'rgb(252, 231, 243)'
        }
    ],
    task_status: [
        {
            id: 0,
            attribute_value: 'Not Started',
            attribute_key: 'NOT_STARTED',
            color: 'var(--ion-color-medium)'
        },
        {
            id: 2,
            attribute_value: 'In progress',
            attribute_key: 'IN_PROGRESS',
            color: 'var(--ion-color-warning-shade)'
        },
        {
            id: 1,
            attribute_value: 'Completed',
            attribute_key: 'COMPLETED',
            color: 'var(--ion-color-completed)'
        },
        {
            id: 3,
            attribute_value: 'On Hold',
            attribute_key: 'ON_HOLD',
            color: 'var(--ion-color-dark)'
        },
        {
            id: 4,
            attribute_value: 'Cancelled',
            attribute_key: 'CANCELLED',
            color: 'var(--ion-color-danger)'
        }
    ],
    inclusion_status: [
        {
            id: 0,
            attribute_value: 'Not Started',
            attribute_key: 'NOT_STARTED',
            color: 'medium',
            icon: 'pending'
        },
        {
            id: 1,
            attribute_value: 'In progress',
            attribute_key: 'IN_PROGRESS',
            color: 'warning-shade',
            icon: 'pending'
        },
        {
            id: 2,
            attribute_value: 'Submitted',
            attribute_key: 'SUBMITTED',
            color: 'primary',
            icon: 'done'
        },
        {
            id: 3,
            attribute_value: 'Completed',
            attribute_key: 'COMPLETED',
            color: 'completed',
            icon: 'verified'
        }
    ],
    unitList: [
        {
            id: 0,
            label: 'm',
            atter_view: 'lm'
        },
        {
            id: 1,
            label: 'm2',
            atter_view: 'm2'
        },
        {
            id: 2,
            label: 'm3',
            atter_view: 'm3'
        },
        {
            id: 4,
            label: 'each',
            atter_view: 'each'
        }
    ],
    costTypeList: [
        {
            id: 0,
            attribute_value: 'Included',
            attribute_key: 'INCLUDED'
        },
        {
            id: 1,
            attribute_value: 'Fixed',
            attribute_key: 'FIXED'
        },
        {
            id: 2,
            attribute_value: 'Variable',
            attribute_key: 'VARIABLE'
        },
        {
            id: 3,
            attribute_value: 'By customer',
            attribute_key: 'BY_CUSTOMER'
        }
    ],


}