export class Tag {
    id: string;
    company_id: string;
    /* tag_name: string; */
    contractors: Array<TagItem>;
    suppliers: Array<TagItem>;
    createdAt: any;
    createdBy: any;
    deleteFlag: string;
    updatedAt: any;
    updatedBy: any;
}

export class TagItem {
    id: string;
    tag_name: string;
}

export class TRADE_TYPE {
    id: string;
    BUILDER: Tag;
}