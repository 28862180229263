import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { forkJoin, Subscription } from 'rxjs';
import { single, take } from 'rxjs/operators';
import { attributeList } from 'src/app/attribute-list/attribute-list';
import { Company } from 'src/app/model/company/company';
import { DocList } from 'src/app/model/doc/doc-list';
import { FilesItem } from 'src/app/model/project/file';
import { FileTag } from 'src/app/model/project/file-tag';
import { Project } from 'src/app/model/project/project';
import { StageList, Task, TaskList } from 'src/app/model/project/task';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';
import { EventService } from 'src/app/services/event/event.service';
import { StageSectionComponent } from '../stage-section/stage-section.component';
import * as _ from 'lodash';
import { messages } from 'src/app/messages-list/messages';
import { SuppliersContractorsList, SuppliersContractors } from 'src/app/model/contractors-suppliers/suppliers-contractors';

@Component({
  selector: 'bt-task-section',
  templateUrl: './task-section.component.html',
  styleUrls: ['./task-section.component.scss'],
})
export class TaskSectionComponent implements OnInit {

  MASTER_TEMPLATES = "MASTER_TEMPLATES";

  taskListCollection: string = "taskList";
  collNameProjects: string = 'projects';
  company_id;
  project_id;
  taskList$: Subscription;
  isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  allTask: Task;
  createTask: boolean = false;

  project$: Subscription;
  company$: Subscription;
  project: Project;
  company: Company;

  tradeTypeList;

  fileList: Array<FilesItem> = [];
  fileTags: FileTag;

  docListCollection: string = "docList";
  docList: DocList;
  public templateVariable = [];
  task_status = attributeList.task_status;
  allTradeTypeList;

  arrayShortByStage = [];
  selectedId = 'SHOW_ALL';
  suppliersContractorsList: SuppliersContractorsList;
  selectedStageId: string;
  selectedTask;
  selectedStage;
  colorList = attributeList.colorList;
  constructor(
    public modalController: ModalController,
    private eventService: EventService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public crudService: CrudService,
    public commonServices: CommonServices,
    public alertController: AlertController
  ) { }


  ngOnInit() {





    /*  */
    this.taskList$ = this.crudService.getWithWhere(this.MASTER_TEMPLATES, 'taskListUserType', '==', 'BUILDER').subscribe(resp => {
      if (resp[0]) {
        this.allTask = resp[0];


        console.log(this.allTask)
        this.allTask.taskList = [];
        this.allTask.parallelTaskList = [];

        this.allTask.stageList.forEach(singleStage => {
          singleStage.taskList.forEach(singleTask => {
            if (singleTask.parentTask == 'NO_PARENT_TASK') {
              singleTask.parentTask = null;
            }
          })
          /* let stageTask = this.allTask.taskList.filter(singleTask => singleTask.stage.id == singleStage.id);
          singleStage.taskList = stageTask; */
          if (singleStage.isOpen == undefined) {
            singleStage.isOpen = true;
          }
        });
        this.setColor();

        console.log(this.allTask.stageList)
      }
      else {
        let task = new Task();
        task.company_id = this.company_id;
        task.project_id = this.project_id;
        let id = this.crudService.afs.createId();
        task.id = id;
        this.crudService.addWithCollName(this.taskListCollection, task).then(resp => {
          this.allTask = task;
        })

      }
    });

    this.crudService.getWithWhere(this.MASTER_TEMPLATES, 'id', '==', 'TRADE_TYPE').subscribe(resp => {
      this.tradeTypeList = resp[0].BUILDER;
      console.log(this.tradeTypeList)
      this.tradeTypeList.contractors.forEach(el => {
        el.type = "contractor";
      });
      this.tradeTypeList.suppliers.forEach(el => {
        el.type = "supplier";
      });

      this.allTradeTypeList = [...resp[0].BUILDER.contractors, ...resp[0].BUILDER.suppliers];
    });


    this.crudService.getWithWhere(this.MASTER_TEMPLATES, 'id', '==', 'FILETAGS').subscribe((respFile: Array<FileTag>) => {
      this.fileTags = respFile[0];
    });


    /* this.crudService.getWithTwoWhereNoTakeCompanyWithProjectIdOtherColl(this.docListCollection, this.project_id).subscribe((respDoc: Array<DocList>) => {
      this.docList = respDoc[0];
      console.log(this.docList)
    }); */


    this.crudService.getWithWhere(this.MASTER_TEMPLATES, 'id', '==', 'NOTIFICATION_TEMPLATE').subscribe(resp => {
      if (resp.length > 0) {
        resp[0].sms.forEach(item => {
          item.type = "sms";
        });
        resp[0].email.forEach(item => {
          item.type = "email";
        });
        resp[0].whastapp.forEach(item => {
          item.type = "whastapp";
        });

        this.templateVariable = [...resp[0].sms, ...resp[0].email, ...resp[0].whastapp]
      }
    });


  }

  setColor() {
    let i = 0;
    this.allTask.stageList.forEach(singleStage => {
      singleStage.taskList.forEach(singleTask => {
        singleTask.displayColor = this.colorList[i];
        i++;
        if (i == 10) {
          i = 0;
        }
      });
    });
    console.log('Color Done')
  }

  addTask() {

  }

  ngOnDestroy() {
    this.taskList$.unsubscribe();
  }

  addStage() {
    const dialogRef = this.dialog.open(StageSectionComponent, {
      data: {
        task: this.allTask,
        collection: this.taskListCollection
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  checkType(task, type) {
    let index = task.selectedTemplate.findIndex(template => template.template_type == type)
    if (index == -1) {
      return false
    }
    return true
  }

  checkStageList(stage, allTask) {
    let index = allTask.findIndex(singleTask => singleTask.stage.id == stage.id);
    if (index == -1) {
      return false
    }
    return true;
  }

  changeStatus(event, task: TaskList, stage: StageList) {

    /* 
    
        let taskList = stage.taskList;
        let index = taskList.findIndex(singleTask => singleTask.id == task.id);
    
    
        if (task.type == "SEQUENCE_TASK") {
          if (taskList[index - 1] && taskList[index - 1].task_status.id != 0 || index == 0) {
            taskList[index].task_status = event;
            this.updateData();
          }
          else {
            if (event.attribute_key == 'IN_PROGRESS') {
              taskList[index].task_status = attributeList.task_status[0];
              this.commonServices.snackBarOpen('Previous Task is not in progress');
              return false;
            }
          }
    
          if (event.attribute_key == 'COMPLETED') {
            this.nextTaskActive(task, stage);
            this.updateData();
          }
    
        }
        else {
          if (event.attribute_key != 'IN_PROGRESS') {
            taskList[index].task_status = event;
            this.updateData();
          }
        }
    
    
        if (event.attribute_key == 'IN_PROGRESS') {
    
          let stageIndex = this.allTask.stageList.findIndex(singleStage => singleStage.id == stage.id)
          let taskIndex = this.allTask.stageList[stageIndex].taskList.findIndex(singleTask => singleTask.id == task.id)
          this.allTask.stageList[stageIndex].taskList[taskIndex].startDate = String(new Date())
          this.sendNotification(task, stage)
        } */
  }


  /* sendNotification(task: TaskList, stage: StageList) {

    console.log(task)

    let attachedFile = [];
    let allFileObserval = [];

    let projectTeam = this.getTeam()
    let selectedTeam = projectTeam.filter(singleTeam => singleTeam.default_trade_id == task.actionBy.id || singleTeam.default_trade_id == task.actionBy.uid)[0];
    selectedTeam = this.suppliersContractorsList.suppliersContractors.filter(suppliersContractor => suppliersContractor.id == selectedTeam.id)[0]

    if (!selectedTeam) {
      this.commonServices.snackBarOpen('Team member is missing please select first')
      return false;
    }

    if (!task.categoryItemId && task.files && task.files.length > 0) {
      task.files.forEach((file, keyIndex) => {
        allFileObserval.push(this.crudService.downloadFile(file.id, 'id'))
      });
      this.downloadAtttachFileAndSend(task, allFileObserval, selectedTeam);
      this.updateData();
    }
    else if (task.categoryItemId && task.itemId) {
      let selectedCategory = this.docList.categoryItem.filter(cat => cat.id == task.categoryItemId)[0];
      let item = selectedCategory.itemList.findIndex(singleItem => singleItem.id == task.itemId);

      if (selectedCategory.itemList[item].attachedPath.length > 0) {
        selectedCategory.itemList[item].attachedPath.forEach((file, keyIndex) => {
          allFileObserval.push(this.crudService.downloadFile(file.id, 'id'))
        });
        this.downloadAtttachFileAndSend(task, allFileObserval, selectedTeam);
        this.updateData();
      }
      else {
        let index = stage.taskList.findIndex(singleTask => singleTask.id == task.id)
        stage.taskList[index].task_status = attributeList.task_status[0];
        this.commonServices.snackBarOpen('Attach files is missing in Document Lists');
        return false;
      }
    }
    else {
      this.notificationProcess(task, null, selectedTeam)
      this.updateData();
    }


  } */

  /* downloadAtttachFileAndSend(task, allFileObserval, selectedTeam) {
    let attachedFile = [];
    forkJoin(allFileObserval).subscribe(res => {
      res.forEach(singleItem => {
        attachedFile.push(this.commonServices.setAttach(singleItem))
      });
      this.notificationProcess(task, attachedFile, selectedTeam)
    });
  } */


  /* notificationProcess(task: TaskList, attachedFile, selectedTeam) {
    let templateType = task.selectedTemplate.findIndex(singleTemplate => singleTemplate.type == 'email');
    let templateTypeSMS = task.selectedTemplate.findIndex(singleTemplate => singleTemplate.type == 'sms');
    if (templateType != -1) {
      let emailTemplate = this.templateVariable.filter(singleTemplate => singleTemplate.id == task.selectedTemplate[templateType].id)[0];
      this.email(task, attachedFile, selectedTeam, emailTemplate)
    }
    else if (templateTypeSMS != -1) {
      let smsTemplate = this.templateVariable.filter(singleTemplate => singleTemplate.id == task.selectedTemplate[templateType].id)[0];
      this.sendSMS(task, attachedFile, selectedTeam, smsTemplate)
    }
  } */



  /* email(selectedTask: TaskList, attachedFile?, selectedTeam?: any, selectedTemplate?) {
    let template = this.commonServices.createTemplate(selectedTemplate.templateContent, this.project.project_client, selectedTeam, this.project, this.company, selectedTask)
    setTimeout(() => {
      this.commonServices.dismissLoading();
    }, 1000)

    let subject;
    if (selectedTemplate.emailSubject) {
      subject = this.commonServices.createTemplate(selectedTemplate.emailSubject, this.project.project_client, selectedTeam, this.project, this.company, selectedTask);
    }


    this.commonServices.openEmailPopup(selectedTeam.email, template, attachedFile, subject, null, this.company, this.project, selectedTeam, selectedTask).afterClosed().subscribe(resp => {
      if (resp) {
        let smsIndex = selectedTask.selectedTemplate.findIndex(template => template.template_type == "SMS")
        if (smsIndex != -1) {
          this.sendSMS(selectedTask, attachedFile, selectedTeam, selectedTemplate)
        }
      }
    })

  } */


  /* sendSMS(selectedTask, attachedFile?, team?, templateItem?: any) {
    setTimeout(() => {
      this.commonServices.dismissLoading();
    }, 1000);

    let template = this.commonServices.createTemplate(templateItem.templateContent, this.project.project_client, team, this.project, this.company, selectedTask);
    var temp = document.createElement('div');
    temp.innerHTML = template;


    this.commonServices.openSMSPopup(null, temp.textContent, attachedFile, this.company.tokenList, this.company, this.project, team, selectedTask).afterClosed().subscribe(result => {
      if (result) {
        this.commonServices.sendNotification(result.mobile.token, team.contact, result.message, selectedTask.title).subscribe()
      }
    });
  } */


  getTeam() {
    if (!this.project.contractorsTeam) {
      this.project.contractorsTeam = []
    }
    if (!this.project.suppliersTeam) {
      this.project.suppliersTeam = []
    }
    if (!this.project.team) {
      this.project.team = []
    }

    let projectTeam = [...this.project.contractorsTeam, ...this.project.suppliersTeam, ...this.project.team];
    return projectTeam;
  }


  nextTaskActive(task, stage) {
    /* let SEQUENCE_TASK = stage.taskList.filter(singleTask => singleTask.type == "SEQUENCE_TASK");
    let nextTaskIndex = SEQUENCE_TASK.findIndex(singleTask => singleTask.position == (task.position + 1));
    if (nextTaskIndex != -1) {
      let nextActiveTaskIndex = stage.taskList.findIndex(singleIndex => singleIndex.id == SEQUENCE_TASK[nextTaskIndex].id)
      if (stage.taskList[nextActiveTaskIndex].task_status.id == 0) {
        stage.taskList[nextActiveTaskIndex].task_status = attributeList.task_status[1];
        stage.taskList[nextActiveTaskIndex].startDate = String(new Date());
        this.sendNotification(stage.taskList[nextActiveTaskIndex], stage)
        this.updateData();
      }
    }
    else {
      let stageIndex = this.allTask.stageList.findIndex(singleStage => singleStage.id == stage.id) + 1;
      if (this.allTask.stageList[stageIndex]) {
        let taskIndex = this.allTask.stageList[stageIndex].taskList.findIndex(singleTask => singleTask.position == (SEQUENCE_TASK.length + 1))
        if (taskIndex != -1 && this.allTask.stageList[stageIndex].taskList[taskIndex].task_status.id == 0) {
          this.allTask.stageList[stageIndex].taskList[taskIndex].task_status = attributeList.task_status[1];
          this.allTask.stageList[stageIndex].taskList[taskIndex].startDate = String(new Date());
          this.sendNotification(this.allTask.stageList[stageIndex].taskList[taskIndex], this.allTask.stageList[stageIndex])
          this.updateData();
        }
      }


    } */
  }

  closeTassTask(event) {
    this.createTask = false;
  }

  drop(event?: CdkDragDrop<TaskList[]>) {
    /* console.log(event)
    let selectedStageTask = this.allTask.taskList
    selectedStageTask.sort((a, b) => {
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
    });

    if (event) {
      moveItemInArray(selectedStageTask, event.previousIndex, event.currentIndex);
    }

    console.log(selectedStageTask)

    let parentPosition = 0;
    selectedStageTask.forEach((item, keyIndex) => {
      if (!item.parentTask) {
        parentPosition++;
        item.position = parentPosition
        let childPosition = 0;
        selectedStageTask.forEach(singleChild => {
          if (singleChild.parentTask == item.id) {
            childPosition++;
            singleChild.position = Number(item.position + '.' + childPosition)
          }

        })
      }
    });
    this.allTask.taskList = _.cloneDeep(selectedStageTask);
 */

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {


      /* let childData: Array<TaskList> = event.previousContainer.data.filter(singleTask => singleTask.parentTask == event.previousContainer.data[event.previousIndex].id)
      console.log(childData)
      childData.forEach(element => {
        event.container.data.push(element)
      }); */

      /* .data.filter(singleTask => singleTask.parentTask != event.previousContainer.data[event.previousIndex].id && event.previousContainer.data[event.previousIndex].id != singleTask.id), */

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    let childData = event.previousContainer.data.filter(singleTask => singleTask.parentTask == event.container.data[event.currentIndex].id)
    console.log(childData)

    let i = 0;
    this.allTask.stageList.forEach(singleStage => {
      singleStage.taskList.forEach(element => {
        if (element.type == "SEQUENCE_TASK") {
          i++;
          element.position = i;
          singleStage.taskList.filter(singleTask => singleTask.parentTask == element.id).forEach((childItem, keyIndex) => {
            childItem.position = Number(element.position + '.' + (keyIndex + 1));
          });
        }
      });
    })


  }


  updateData() {

    /* let inProgressTask = this.allTask.taskList.filter(task => task.task_status.id == 2);
    if (inProgressTask.length > 0) {
      inProgressTask.sort((a, b) => {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
      });
    } */

    /* this.project.projectDetail.task_total = this.allTask.taskList.filter(singleTask => singleTask.type == 'SEQUENCE_TASK').length;
    this.project.projectDetail.completed_task_total = this.allTask.taskList.filter(task => task.task_status.id == 1).length;
    this.project.projectDetail.completed_task_total = this.allTask.taskList.filter(task => task.task_status.id == 1).length;
    this.crudService.updateWithCollNamer(this.collNameProjects, this.project, this.project.id) */
    
    this.crudService.updateWithCollNamer(this.MASTER_TEMPLATES, this.allTask, this.allTask.id).then(resp => {
      this.commonServices.snackBarOpen(messages.DETAIL_UPDATED)
    }).catch(error => {
      this.commonServices.snackBarOpen(error.message)
    })
  }

  openCloseStage(stage: StageList) {
    /* this.selectedStageId = stage.id; */
    let index = this.allTask.stageList.findIndex(singleStage => singleStage.id == stage.id)
    if (this.allTask.stageList[index].isOpen) {
      this.allTask.stageList[index].isOpen = false
    }
    else {
      this.allTask.stageList[index].isOpen = true
    }
  }

  openAllPurchase() {
    if (this.selectedStageId == 'ALL') {
      this.selectedStageId = undefined;
      this.allTask.stageList[0].isOpen = true;
    } else {
      this.selectedStageId = 'ALL';
    }
  }

  updateTask(item, stage) {
    this.createTask = true;
    this.selectedTask = item;
    this.selectedStage = stage;
  }

}