import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { paymentTypeList } from 'src/app/model/project/payments-contract';

@Component({
  selector: 'app-user-type-selectbox',
  templateUrl: './user-type-selectbox.component.html',
  styleUrls: ['./user-type-selectbox.component.scss'],
})
export class UserTypeSelectboxComponent implements OnInit {

  paymentTypeList = paymentTypeList;
  //selectedUserType = this.paymentTypeList[0].id;
  @Input() selectedUserType;
  @Output() selectionChange = new EventEmitter();

  constructor() { }

  ngOnInit() { 
    if(!this.selectedUserType){
      this.selectedUserType = this.paymentTypeList[0].id;
    }
  }

  changeUserTypeFn() {
    this.selectionChange.emit(this.selectedUserType)
  }


}
