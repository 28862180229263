import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FilesItem } from 'src/app/model/project/file';
import { SingleNote } from 'src/app/model/project/note';
import { Category } from 'src/app/model/company/inclusion';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';
import { Lightbox, LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { take } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { attributeList } from 'src/app/attribute-list/attribute-list';
import { Router } from '@angular/router';
import { Services } from 'src/app/model/sales-documents/services';
import { SalesDocument } from 'src/app/model/sales-documents/salesDocument';
import { Project } from 'src/app/model/project/project';
import { Company } from 'src/app/model/company/company';
import { messages } from 'src/app/messages-list/messages';
import { Inventory } from 'src/app/model/inventory/inventory';

@Component({
  selector: 'bt-inclusion-stepper',
  templateUrl: './inclusion-stepper.component.html',
  styleUrls: ['./inclusion-stepper.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class InclusionStepperComponent implements OnInit {

  subcategoryFormGroup: FormGroup;
  selectionFormGroup: FormGroup;
  isLinear: boolean = true;
  @ViewChild('stepper') stepper: MatStepper;
  clientSelection: boolean = false;
  inclusion_status = attributeList.inclusion_status;

  public Editor = ClassicEditor;
  content: string;
  multiple_choice: Array<{ id: string, choice: string }> = [];
  choice_data = [];
  company_id;
  company: Company
  /* project_id; */
  viewMode: boolean = false;
  _albums = [];
  @Output() onSave = new EventEmitter();
  selectedIndex = 0;
  isLoading: boolean = false;
  project_id;
  project: Project;
  private _subscription: Subscription;
  MASTER_TEMPLATES = 'MASTER_TEMPLATES';

  displayedColumns: string[] = ['service', 'rate', 'qty', 'tax', 'total'];

  inventory;
  subCategories;
  selectedSubCategoryId;




  constructor(
    private formBuilder: FormBuilder,
    private commonServices: CommonServices,
    private crudService: CrudService,
    public dialogRef: MatDialogRef<InclusionStepperComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public _lightbox: Lightbox,
    public dialog: MatDialog,
    public router: Router,
    private _lightboxEvent: LightboxEvent,
  ) { }

  ngOnInit() {

    this.subcategoryFormGroup = this.formBuilder.group({
      id: [this.commonServices.getUniqueId()],
      name: ['', Validators.required],
      amount: [''],
      description: ['', Validators.required],
      clientSelection: [false, Validators.required],
      attachedPath: [new Array<FilesItem>()],
      noteList: [new Array<SingleNote>()],
      finalize: [false],
      selectionData: [],
      status: [this.inclusion_status[0]],
      showInInclusion: [false]
    });

    this.selectionFormGroup = this.formBuilder.group({
      selectionList: this.formBuilder.array([])
    });
    if (this.data.subCategory) {
      if (!this.data.subCategory.status) {
        this.data.subCategory.status = this.inclusion_status[0];
      }
      this.subcategoryFormGroup.patchValue(this.data.subCategory);
      if (this.data.subCategory.selectionData) {
        this.data.subCategory.selectionData.forEach((element, selectionIndex) => {
          this.addClientSelection(0);
          element.multipleChoice.forEach((singleChoice, choiceIndex) => {
            if (choiceIndex != 0) {
              this.addChoice(selectionIndex)
            }
          });
        });
        this.selectionFormGroup.controls.selectionList.patchValue(this.data.subCategory.selectionData)
      }
    }

    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe(event => this._onReceivedEvent(event));
    this.collectInventory();

  }

  clearAdditionalForms(): void {
    this.stepper._stateChanged();
  }

  selectSlide(i) {
    this.stepper.selectedIndex = i;
    let selectionFormGroup = this.selectionFormGroup.value;
    if (selectionFormGroup.selectionList[i - 1] && selectionFormGroup.selectionList[i - 1].id) {
      this.previewImage(selectionFormGroup.selectionList[i - 1].id);
    }
  }

  createUpdateSubCategory(close?) {
    if (this.subcategoryFormGroup.valid) {
      let index = this.data.category.subCategory.findIndex(subCategory => subCategory.id == this.subcategoryFormGroup.value.id)
      if (index == -1) {
        this.data.category.subCategory.push(this.subcategoryFormGroup.value);
      }
      else {
        this.data.category.subCategory[index] = this.subcategoryFormGroup.value;
      }

      /* this.onSave = new EventEmitter(); */
      this.onSave.emit(this.data.category);
      if (close != false) {
        this.dialogRef.close()
      }
    }
  }


  getControls() {
    return (this.selectionFormGroup.get('selectionList') as FormArray).controls;
  }

  getLengthOfForm() {
    let selectionList = this.selectionFormGroup.controls.selectionList as FormArray;
    return selectionList.length;
  }

  addClientSelection(slideIndex?) {
    let selectionList = this.selectionFormGroup.controls.selectionList as FormArray;
    let arrayLen = selectionList.length;
    let newGroup = this.formBuilder.group({
      id: [this.commonServices.getUniqueId()],
      title_question: ['', Validators.required],
      ans_type: ['', Validators.required],
      multipleChoice: this.formBuilder.array([
        this.formBuilder.group({
          id: [this.commonServices.getUniqueId()],
          choice: ['']
        })
      ]),
      subCategoryId: [''],
      selectionGuide: [''],
      attachment: [new Array<FilesItem>()],
      noteList: [new Array<SingleNote>()],
      variationService: [new Array<Services>()]
    });
    selectionList.insert(arrayLen, newGroup);
    this.subcategoryFormGroup.get('clientSelection').setValue(true);
    setTimeout(() => {
      if (slideIndex == 0) {
        this.selectSlide(slideIndex);
      }
      else {
        this.selectSlide(arrayLen + 1);
      }

    }, 600)
  }

  deleteSelectionItem() {
    let selectionListFormArray = this.selectionFormGroup.controls.selectionList as FormArray;
    let arrayLen = selectionListFormArray.length;
    selectionListFormArray.removeAt(this.stepper.selectedIndex - 1);
  }

  saveSelection(close?) {
    if (this.stepper.steps.length == 1) {
      this.createUpdateSubCategory();
    }
    else {
      if (this.selectionFormGroup.valid) {
        this.subcategoryFormGroup.get('selectionData').setValue(this.selectionFormGroup.value.selectionList)
        this.createUpdateSubCategory(close);
      }
    }
  }

  addChoice(i) {
    let selectionList = this.selectionFormGroup.controls.selectionList as FormArray;
    let multipleChoice = selectionList.controls[i]['controls'].multipleChoice as FormArray
    let arrayLen = multipleChoice.length;
    let newItem = this.formBuilder.group({
      id: [this.commonServices.getUniqueId()],
      choice: ['', Validators.required]
    })
    multipleChoice.insert(arrayLen, newItem);
  }


  uploadFile(file, id, i) {
    this.isLoading = true;
    let selectionFormGroup = this.selectionFormGroup.value;
    let path = this.company_id + '/selection/' + id;

    let allFileSub = [];

    Array.prototype.forEach.call(file, (singleFile, keyIndex) => {
      const formData = this.commonServices.formData(singleFile, path);
      allFileSub.push(this.crudService.uploadFile(formData).pipe(take(1)))
    });

    forkJoin(allFileSub).subscribe((folderResp: any) => {
      Array.prototype.forEach.call(file, (singleFile, keyIndex) => {
        let filesItem = this.commonServices.uploadFile(folderResp[keyIndex], id, null)
        let attachIndex = selectionFormGroup.selectionList[i].attachment.findIndex(singleFile => singleFile.id == folderResp[keyIndex][0].id)
        if (attachIndex == -1) {
          selectionFormGroup.selectionList[i].attachment.push(filesItem)
        }
        else {
          selectionFormGroup.selectionList[i].attachment[attachIndex] = filesItem;
        }

        const album = {
          src: folderResp[keyIndex][1].value[0].large.url,
          caption: "",
          thumb: folderResp[keyIndex][1].value[0].large.url
        };
        this._albums.push(album);
      });

      this.isLoading = false;
      ((this.selectionFormGroup.get('selectionList') as FormArray).at(i) as FormGroup).get('attachment').setValue(selectionFormGroup.selectionList[i].attachment)
      this.onSave.emit(this.data.category)
      /* this.previewImage(selectionFormGroup.id) */

    }, error => {
      this.isLoading = false;
    })
  }

  previewImage(id) {
    let path = this.company_id + '/selection/' + id;
    this._albums = [];
    this.crudService.getThumbnail(path, null, 'path').subscribe((resp: any) => {
      if (resp && resp.value && resp.value.length) {

        resp.value.forEach(singleThumb => {
          if (singleThumb.thumbnails) {
            const album = {
              src: singleThumb.thumbnails[0].large.url,
              caption: "",
              thumb: singleThumb.thumbnails[0].large.url
            };
            this._albums.push(album);
          }
        });
      }
    })
  }

  openPopup() {

    this._lightbox.open(this._albums, 0, { wrapAround: true, showImageNumberLabel: true });
  }

  _onReceivedEvent(event) {
    console.log(event)
  }

  changeValidation(value, index) {

    let totalLength = (((this.selectionFormGroup.get('selectionList') as FormArray).at(index) as FormGroup).get('multipleChoice') as FormArray).length;
    let selectionList = ((this.selectionFormGroup.get('selectionList') as FormArray).at(index) as FormGroup);
    let selectedType = ((this.selectionFormGroup.get('selectionList') as FormArray).at(index) as FormGroup).get('ans_type').value;

    if (selectedType == 'SHORT_TEXT') {
      ((this.selectionFormGroup.get('selectionList') as FormArray).at(index) as FormGroup).get('subCategoryId').clearValidators();
      for (let x = 0; x < totalLength; x++) {
        let multipleChoice = ((selectionList.get('multipleChoice') as FormArray).at(x) as FormGroup);
        multipleChoice.get('choice').clearValidators();
      }
    }
    else if (selectedType == "MULTIPLE_CHOICE") {
      ((this.selectionFormGroup.get('selectionList') as FormArray).at(index) as FormGroup).get('subCategoryId').clearValidators();
      for (let x = 0; x < totalLength; x++) {
        let multipleChoice = ((selectionList.get('multipleChoice') as FormArray).at(x) as FormGroup);
        multipleChoice.get('choice').setValidators(Validators.required);
      }
    }
    else {
      for (let x = 0; x < totalLength; x++) {
        let multipleChoice = ((selectionList.get('multipleChoice') as FormArray).at(x) as FormGroup);
        multipleChoice.get('choice').clearValidators();
      }
      ((this.selectionFormGroup.get('selectionList') as FormArray).at(index) as FormGroup).get('subCategoryId').setValidators(Validators.required);
    }
  }

  goToPrev() {
    this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
  }

  goToNext() {
    this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
  }

  deleteChoice(selectionIndex, choiceIndex) {
    let selectionListFormArray = (this.selectionFormGroup.controls.selectionList as FormArray).at(selectionIndex) as FormGroup;

    let x = selectionListFormArray.controls.multipleChoice as FormArray;
    x.removeAt(choiceIndex);
  }

  openCommentBox(i) {
    /* const dialogRef = this.dialog.open(NotesSectionComponent, {
      width: '650px',
      height: '50vh',
      data: {
        company_id: this.company_id,
        project_id: this.project_id,
        selectedInclusion: this.data.selectedInclusion,
        categoryIndex: this.data.categoryIndex,
        subCategory: this.data.subCategory,
        selectionDataIndex: i
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    }); */
  }

  openPreview() {
    /* console.log(this.data.category.id) */
    window.open(window.location.href + '/' + this.data.subCategory.id)
  }

  addVariationModel(selectionIndex) {

  }

  showVariation(length) {
    this.stepper.selectedIndex = length - 1;
  }


  countVariationTotal() {
    let variationTotal: number = 0;
    this.selectionFormGroup.value.selectionList.forEach(selection => {
      selection.variationService.forEach(service => {
        variationTotal = variationTotal + Number(service.total)
      })
    });
    return variationTotal;
  }

  viewVariationEstimate() {
    this.dialogRef.close()
    this.router.navigateByUrl('/project/payments/' + this.company_id + '/' + this.project_id + '#variation')
  }

  closePopup() {
    this.dialogRef.close();
    /* let dialogRef = this.commonServices.alertConfirmationPopup('Would you like save your changes ?');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveSelection()
      }
      else {
        
      }
    }); */
  }

  collectInventory() {
    this.crudService.collection$(this.MASTER_TEMPLATES, res => res.where('inventoryType', '==', 'BUILDER')).subscribe((resp: Array<Inventory>) => {
      this.inventory = resp[0];
      this.subCategories = this.inventory.subCategories;
      if (this.inventory && this.inventory.item) {
        this.data.subCategory.selectionData.forEach(singleSelect => {
          if (singleSelect.ans_type == 'CATALOGUES') {
            this.setThumbnail(singleSelect.subCategoryId)
          }
        });
      }
    })
  }

  selectInventory(subCategoryId, index) {
    this.selectedSubCategoryId = subCategoryId;
    this.setThumbnail(subCategoryId);
  }

  filteredReturn(ev) {
    this.subCategories = ev;
  }

  setThumbnail(selectedSubCategoryId) {
    this.crudService.getThumbnail('MASTER_TEMPLATES/inventory/' + selectedSubCategoryId, false, 'path').subscribe((resp: any) => {
      if (resp && resp.value) {
        resp.value.forEach(singleThumb => {
          if (singleThumb && singleThumb.thumbnails) {
            this.inventory.item.forEach(singleItem => {
              singleItem.attachment.forEach(singleAttachment => {
                if (singleAttachment.id == singleThumb.id) {
                  singleAttachment.thumbnail = singleThumb.thumbnails[0].large
                }
              })
            })
          }
        });
      }
    })
  }

}
