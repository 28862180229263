import { SalesDocument } from "../sales-documents/salesDocument";

export class PaymentsContract {
    id: string;
    payments: Array<Payments> = [];
    variation: Array<Variation> = [];
    company_id: string;
    project_id: string;
    contractPrice: number = 0;

    updatedAt: string;
    deleteFlag: string;
    createdBy: string;
    updatedBy: string;
}

export class Payments {
    id: string;
    title: string;
    description: string;
    contract_percentage: string;
    amount: number = 0;
    payment_invoice: SalesDocument;
    status: any;
    position: number;
}

export class Variation {
    id: string;
    amount: number;
    name: string;
    description: string;
    status: any;
    payment_invoice: SalesDocument;
    position: number;
}


export class PROGRESS_PAYMENTS {
    id: string = "PROGRESS_PAYMENTS";
    BUILDER: Array<any> = null;
    PLUMBER: Array<any> = null;
}


export const paymentTypeList = [
    {
        id: 'BUILDER',
        label: 'Builder'
    },
    {
        id: 'PLUMBER',
        label: 'Plumber'
    }
]