import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Company } from 'src/app/model/company/company';
import { Storage } from '@ionic/storage-angular';
import { User } from 'src/app/model/user/user';
import { environment } from 'src/environments/environment';

const USER = "USER";

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  firebase = firebase.default;
  private afsCollection: AngularFirestoreCollection<any>;
  private collObs: Observable<any[]>;
  public afs: AngularFirestore;
  /* baseURL = "https://calm-ravine-12528.herokuapp.com"; */
  baseURL = environment.API_URL;
  /* baseURL = "http://localhost:5000"; */


  constructor(
    afs: AngularFirestore,
    public angularFireStorage: AngularFireStorage,
    private httpClient: HttpClient,
    private storage: Storage,
  ) {
    this.afs = afs;
  }





  collection$(path, query?) {
    return this.afs
      .collection(path, query)
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: Object = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  /* doc$(path): Observable<any> {
    return this.afs
      .doc(path)
      .snapshotChanges()
      .pipe(
        map(doc => {
          return { id: doc.payload.id, ...doc.payload.data() };
        })
      );
  } */

  //Snapshot Changes - get all docs from collection where delete flag is N
  snapshotChanges(collName, QRY?) {
    if (QRY)
      this.afsCollection = this.afs.collection<any>(collName, ref => ref.orderBy('updatedAt', 'desc'));
    else
      this.afsCollection = this.afs.collection<any>(collName, ref => ref.where("deleteFlag", "==", "N").orderBy('updatedAt', 'desc'));

    //this.afsCollection.ref.where("deleteFlag","==","N");  
    this.collObs = this.afsCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }


  getCollection() {
    return this.collObs;
  }

  remove(id) {
    return this.afsCollection.doc(id).delete();
  }

  softRemove(obj, id: string) {
    obj["deleteFlag"] = 'Y';
    return this.afsCollection.doc(id).update(obj);
  }
  softRemoveCollum(collName, obj, id: string) {
    obj["deleteFlag"] = 'Y';
    return this.afs.collection<any>(collName).doc(id).update(obj);
  }

  get(id) {
    return this.afsCollection.doc<any>(id).valueChanges().pipe(take(1));
  }

  getWithWhere(collName, field: string, oprator, fieldValue: string) {

    return this.afs.collection<any>(collName, ref => ref.where("deleteFlag", "==", "N").where(field, oprator, fieldValue).orderBy('updatedAt', 'desc')).snapshotChanges().pipe(
      take(1),
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );;
  }

  getWithWhereNoTake(collName, field: string, oprator, fieldValue: string) {
    return this.afs.collection<any>(collName, ref => ref.where("deleteFlag", "==", "N").where(field, oprator, fieldValue).orderBy('updatedAt', 'desc')).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );;
  }

  getWithTwoWhereNoTake(collName, field: string, oprator, fieldValue: string, field_1: string, oprator_1, fieldValue_1: any) {
    return this.afs.collection<any>(collName, ref => ref.where("deleteFlag", "==", "N").where(field, oprator, fieldValue).where(field_1, oprator_1, fieldValue_1)).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );;
  }


  getSingleDoc(collName, id) {
    this.afsCollection = this.afs.collection<any>(collName, ref => ref.where("deleteFlag", "==", "N"));
    return this.afsCollection.doc<any>(id).valueChanges();
  }

  update(obj, id: string) {
    obj = JSON.parse(JSON.stringify(obj))
    obj["updatedBy"] = window.localStorage.getItem('uid');
    obj["deleteFlag"] = "N";
    obj["updatedAt"] = new Date().getTime();
    return this.afsCollection.doc(id).update(obj);
  }

  updateWithCollNamer(collName, obj, id: string) {
    obj = JSON.parse(JSON.stringify(obj))
    obj["updatedBy"] = window.localStorage.getItem('uid');
    obj["deleteFlag"] = "N";
    obj["updatedAt"] = new Date().getTime();
    return this.afs.collection<any>(collName).doc(id).update(obj);
  }

  add(obj, id?) {
    if (!id) {
      id = this.afs.createId();;
    }

    obj["id"] = id;
    obj["createdBy"] = window.localStorage.getItem('uid');
    obj["createdAt"] = new Date().getTime();
    obj["updatedBy"] = "";
    obj["updatedAt"] = new Date().getTime();
    obj["deleteFlag"] = "N";
    //return this.afsCollection.add(obj);
    obj = JSON.parse(JSON.stringify(obj));
    return this.afsCollection.doc(id).set(obj, { merge: true });
  }

  addWithCollName(collName, obj, id?) {
    /* let id = this.afs.createId(); */
    if (!id) {
      id = this.afs.createId();;
    }
    obj["id"] = id;
    obj["createdBy"] = window.localStorage.getItem('uid');
    obj["createdAt"] = new Date().getTime();
    obj["updatedBy"] = "";
    obj["updatedAt"] = new Date().getTime();
    obj["deleteFlag"] = "N";
    //return this.afsCollection.add(obj);
    obj = JSON.parse(JSON.stringify(obj));
    return this.afs.collection<any>(collName).doc(id).set(obj, { merge: true });
  }

  searchQuery(collName, startAt, endAt) {
    return this.afs.collection<any>(collName, ref => ref.orderBy('last_name').startAt(startAt).endAt(endAt).where("deleteFlag", "==", "N").limit(5)).snapshotChanges().pipe(
      take(1),
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );;
  }

  fileUpload(path, file) {
    let ref = this.angularFireStorage.ref(path)
    let task = ref.put(file);
    return task.snapshotChanges().pipe(
      /* take(1), */
      map(action => {
        return action.ref
      }))
  }



  uploadFile(val) {
    return this.httpClient.post(this.baseURL + '/file-upload', val, {});
  }

  createFolder(folderName, path) {
    return this.httpClient.post(this.baseURL + '/create-folder', { folderName, path });
  }

  downloadFile(path, type) {
    return this.httpClient.post(this.baseURL + '/download-file', { path, type });
  }
  delete(file, archive) {
    return this.httpClient.post(this.baseURL + '/delete', { file, archive });
  }
  shareLink(id) {
    return this.httpClient.post(this.baseURL + '/share-link', { id });
  }

  getThumbnail(id, children?, type?) {
    return this.httpClient.post(this.baseURL + '/thumbnail', { id, children, type });
  }

  auth(id?, uid?) {
    let header = this.setHeaderToken();
    return this.httpClient.post(environment.API_URL + '/auth-firebase', { id: id }, { headers: header });
  }

  setHeaderToken(header?) {
    if (!header) {
      header = new HttpHeaders();
    }
    header = header.append('authorization', window.localStorage.getItem('token'));
    return header;
  }

}