import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { CrudService } from '../crud-service/crud-service.service';
import { Storage } from '@ionic/storage-angular';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthServices {

  firebase = firebase.default;

  constructor(
    private angularFireAuth: AngularFireAuth,
    public crudService: CrudService,
    private storage: Storage,
    private router: Router,
  ) {
  }


  userLoginWithEmailPassword(userName, password) {
    return this.angularFireAuth.signInWithEmailAndPassword(userName, password)
  }

  checkSession() {
    return this.angularFireAuth.authState;
  }


  logout(): Promise<any> {
    return this.angularFireAuth.signOut()
  }

  loginWithSocialMedia(type) {
    let socialMediaType;
    if (type == 'GOOGLE') {
      socialMediaType = new this.firebase.auth.GoogleAuthProvider()
    }
    else if (type == 'FACEBOOK') {
      socialMediaType = new this.firebase.auth.FacebookAuthProvider()
    }
    return this.angularFireAuth.signInWithPopup(socialMediaType);
  }

  createUserWithEmail(userName, password) {
    return this.angularFireAuth.createUserWithEmailAndPassword(userName, password);
  }



  signInWithPhoneNumber(phoneNumber, recaptchaVerifier) {
    return this.angularFireAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
  }

  verifyOtp(otp, verificationId) {
    var credential = this.firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
    return this.firebase.auth().currentUser.linkWithCredential(credential);
  }

  updateEmail(email) {
    const user = this.firebase.auth().currentUser;
    return user.updateEmail(email);
  }

  registerTeamAndClient(email, password) {
    var secondaryApp = this.firebase.initializeApp(environment.firebaseConfig, "Secondary");
    let auth = secondaryApp.auth().createUserWithEmailAndPassword(email, password)
    return { secondaryApp, auth }
  }

  tokenUpdate() {
    return this.firebase.auth().currentUser.getIdTokenResult(true)
  }

  getCurrentUser(){
    return this.firebase.auth().currentUser
  }

}