import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { FormValidationService } from 'src/app/services/form-validation/form-validation.service';
import { Task, TaskList } from 'src/app/model/project/task';
import { messages } from 'src/app/messages-list/messages';
import { FileMain, FilesItem } from 'src/app/model/project/file';
import * as _ from 'lodash';
import { FileTag } from 'src/app/model/project/file-tag';
import { attributeList } from 'src/app/attribute-list/attribute-list';
import { Project } from 'src/app/model/project/project';
/* import { TemplateViewComponent } from 'src/app/components/template-view/template-view.component'; */
import { MatDialog } from '@angular/material/dialog';
import { Company } from 'src/app/model/company/company';
/* import { AppDownloadComponent } from '../app-download/app-download.component'; */
import { DocList } from 'src/app/model/doc/doc-list';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'bt-add-sequence-task',
  templateUrl: './add-sequence-task.component.html',
  styleUrls: ['./add-sequence-task.component.scss'],
})
export class AddSequenceTaskComponent implements OnInit, OnChanges {

  @Input() collection;
  @Input() task: Task;
  @Input() project: Project;
  @Input() company_id;
  @Input() project_id;
  @Input() selectedStage;
  @Input() selectedTaskData: TaskList;
  @Input() selectedTaskType;
  @Input() parentTask;
  @Input() createTask;
  @Input() selectType;

  @Output() createTaskPopup: EventEmitter<any> = new EventEmitter();
  selectedTask: string = 'SEQUENCE_TASK';

  public variables = [];
  public filteredList1;
  tradeTypeList: any;
  public templateVariable = []
  public filteredList2;

  taskForm: FormGroup;
  selectedStageParentTask;
  fileList: Array<FilesItem> = [];
  fileListWithFilter: Array<FilesItem> = [];
  fileTags: FileTag;
  defaultSelected = "all";

  docListCollection: string = "docList";
  docList: DocList;
  categoryItem = [];
  actionList = []
  taskListCollection: string = "MASTER_TEMPLATES";
  constructor(
    public modalController: ModalController,
    public commonServices: CommonServices,
    public crudService: CrudService,
    private formBuilder: FormBuilder,
    public formValidationService: FormValidationService,
    public dialog: MatDialog,
  ) {

    this.taskForm = this.formBuilder.group({
      id: [this.commonServices.getUniqueId()],
      type: ['', Validators.compose([Validators.required])],
      stage: ['', Validators.compose([Validators.required])],
      title: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],
      actionBy: ['', Validators.compose([Validators.required])],
      action: [false, Validators.compose([Validators.required])],
      selectedTemplate: ['', Validators.compose([Validators.required])],
      taskDuration: ['', Validators.compose([Validators.required])],
      files: [''],
      startDate: [''],
      endDate: [''],
      purchaseOrder: [false],
      purchaseItem: [false],
      parentTask: [''],
      deleteFlag: ['N'],
      purchaseReminders: [null],
      task_status: [attributeList.task_status[0]],
      position: [1],
      createdDate: [new Date()],
      categoryItemId: [''],
      itemId: [''],
    })

  }

  ngOnInit() {

    if (this.collection != "taskList") {
      this.setTask("PARALLEL_TASK")
      this.taskForm.get('parentTask').setValue('NO_PARENT_TASK');

    }



    this.crudService.getWithWhereNoTake(this.taskListCollection, 'id', '==', 'TRADE_TYPE').subscribe(resp => {
      this.tradeTypeList = resp[0].BUILDER;
      this.tradeTypeList.contractors.forEach(el => {
        el.type = "contractor";
      });
      this.tradeTypeList.suppliers.forEach(el => {
        el.type = "supplier";
      });
      this.variables = [...this.tradeTypeList.contractors, ...this.tradeTypeList.suppliers];
      this.filteredList1 = [...this.tradeTypeList.contractors, ...this.tradeTypeList.suppliers];
    })

    this.crudService.getWithWhereNoTake(this.taskListCollection, 'id', '==', 'NOTIFICATION_TEMPLATE').subscribe(resp => {
      if (resp.length > 0) {
        resp[0].sms.forEach(item => {
          item.type = "sms";
        });
        resp[0].email.forEach(item => {
          item.type = "email";
        });
        resp[0].whastapp.forEach(item => {
          item.type = "whastapp";
        });

        this.templateVariable = [...resp[0].sms, ...resp[0].email, ...resp[0].whastapp]
        this.filteredList2 = [...resp[0].sms, ...resp[0].email, ...resp[0].whastapp]
      }
    });


    /* this.crudService.getWithWhereCompany('fileTags').subscribe((respFile: Array<FileTag>) => {
      this.fileTags = respFile[0];
      this.fileList = [];
      this.fileListWithFilter = [];
      this.categoryItem = undefined;
      respFile[0].tags.forEach((singleTag, keyIndex) => {
        let id = this.project_id + '_' + singleTag.masterId
        let files: Subscription = this.crudService.collection$('files', qry => qry.where('company_id', '==', this.company_id).where('id', '==', id)).pipe(take(1)).subscribe((resp: any) => {
          this.fileList.push(...resp[0].files);
          if (resp[0].files.length == 0 || resp[0].files.filter(file => file.fileType != 'folder').length == 0) {
            this.fileTags.tags = this.fileTags.tags.filter(tag => tag.id != singleTag.id);
          }

          this.fileList = this.fileList.filter(file => file.fileType != 'folder')
          this.fileListWithFilter = this.fileList;
        });
      });
    }); */

    if (this.task && !this.selectedTaskData) {
      let taskList = this.task.taskList.filter(singleTask => singleTask.stage.id == this.selectedStage.id && singleTask.deleteFlag != 'Y');
      this.taskForm.get('position').setValue(taskList.length + 1);
    }



    /* this.crudService.getWithTwoWhereNoTakeCompanyWithProjectIdOtherColl(this.docListCollection, this.project_id).subscribe((respDoc: Array<DocList>) => {
      this.docList = respDoc[0];
    }) */
  }

  closePopup() {
    this.resetValue();
    this.createTaskPopup.emit(false);
  }

  ngOnChanges() {
    if (this.task && this.task.taskList && this.task.taskList.length > 0) {
      this.selectedStageParentTask = this.task.taskList.filter(singleTask => singleTask.deleteFlag != 'Y' && singleTask.type == "SEQUENCE_TASK" && singleTask.stage.id == this.selectedStage.id)
    }
    if (this.selectType) {
      this.setTask(this.selectType);
    }

    if (this.selectedTaskData && !this.selectedTaskType) {
      this.setTask(this.selectedTaskData.type);
    }
    else if (this.selectedTaskType) {
      this.setTask(this.selectedTaskType);
    }


    this.fileValidate(this.taskForm.get('action').value)

    if (!this.selectedTaskData) {
      this.resetValue();
    }

  }

  setTask(taskTypeValue: string) {
    this.selectedTask = taskTypeValue;
    if (this.task) {
      this.selectedStageParentTask = this.task.taskList.filter(singleTask => singleTask.deleteFlag != 'Y' && singleTask.type == "SEQUENCE_TASK" && singleTask.stage.id == this.selectedStage.id);
    }


    if (this.selectedTask == "PARALLEL_TASK") {
      /* this.taskForm.controls['parentTask'].setValidators([Validators.required]) */
    }
    else {
      /* this.taskForm.controls['parentTask'].clearValidators(); */
      this.taskForm.get('purchaseItem').setValue(false)
      this.selectedTaskType = undefined;
    }

    /* this.taskForm.controls['parentTask'].updateValueAndValidity() */

    if (this.selectedTaskData) {
      this.taskForm.patchValue(this.selectedTaskData)
      this.actionList = this.taskForm.get('selectedTemplate').value;
    }

    if (this.selectedTaskType) {
      setTimeout(() => {
        this.taskForm.get('purchaseItem').setValue(true)
        this.taskForm.get('parentTask').setValue(this.parentTask.id);
      }, 500)
    }

  }

  taskCreate() {
    this.taskForm.get('stage').setValue(this.selectedStage)
    this.taskForm.get('type').setValue(this.selectedTask)
    this.setPosition();
    this.fileValidate(this.taskForm.get('action').value)
    this.purchaseItemValidate(this.taskForm.get('purchaseItem').value)

    console.log(this.taskForm.value)

    if (this.taskForm.valid) {
      if (!this.task) {
        this.task = new Task();
        this.task.company_id = this.company_id;
        this.task.project_id = this.project_id;
        this.task.taskList = new Array<TaskList>();
        this.task.parallelTaskList = new Array<TaskList>();
      }

      if (!this.task.parallelTaskList) {
        this.task.parallelTaskList = new Array<TaskList>();
      }
      if (!this.task.taskList) {
        this.task.taskList = new Array<TaskList>();
      }

      if (this.selectedTask == "PARALLEL_TASK") {
        let index = this.task.parallelTaskList.findIndex(singkeTask => singkeTask.id == this.taskForm.value.id);
        if (index == -1) {
          this.task.parallelTaskList.push(this.taskForm.value)
        }
        else {
          this.task.parallelTaskList[index] = this.taskForm.value;
        }

      }
      else {

        let index = this.task.taskList.findIndex(singleTask => singleTask.id == this.taskForm.value.id);
        if (index != -1) {
          this.task.taskList[index] = this.taskForm.value;
        }
        else {
          this.task.taskList.push(this.taskForm.value)
        }


      }

      this.commonServices.presentLoading();
      if (!this.task.id) {
        this.crudService.addWithCollName(this.collection, this.task).then(resp => {
          this.commonServices.dismissLoading();
          this.resetValue();
        }).catch(error => {
          this.commonServices.dismissLoading();
        })
      }
      else {

        this.crudService.updateWithCollNamer(this.collection, this.task, this.task.id).then(resp => {
          this.commonServices.dismissLoading();
          this.resetValue();
        }).catch(error => {
          this.commonServices.dismissLoading();
        })
      }
    }
    else {
      this.commonServices.alertPopup('Error', messages.PLEASE_FILL)
    }
  }

  resetValue() {
    /* this.closePopup(); */
    this.selectedTaskData = null;
    this.taskForm.reset();
    this.taskForm.get('id').setValue(this.commonServices.getUniqueId())
    this.taskForm.get('action').setValue(false)
    this.taskForm.get('createdDate').setValue(new Date())
    this.taskForm.get('purchaseItem').setValue(false)
    this.taskForm.get('purchaseOrder').setValue(false)
    this.taskForm.get('purchaseItem').setValue(false)
    this.taskForm.get('purchaseReminders').setValue(null)
    this.taskForm.get('task_status').setValue(attributeList.task_status[0])
    this.taskForm.controls['files'].clearValidators()
    this.taskForm.controls['files'].updateValueAndValidity();
    this.actionList = [];

    if (this.collection != "taskList") {
      this.taskForm.get('parentTask').setValue('NO_PARENT_TASK');
    }


  }

  fileValidate(event) {
    if (event) {
      this.taskForm.controls['files'].setValidators([Validators.required])
    }
    else {
      this.taskForm.controls['files'].clearValidators()
    }
    this.taskForm.controls['files'].updateValueAndValidity()
  }

  purchaseItemValidate(event) {
    if (event) {
      this.taskForm.controls['purchaseReminders'].setValidators([Validators.required])
      this.taskForm.controls['taskDuration'].clearValidators()
    }
    else {
      this.taskForm.controls['taskDuration'].setValidators([Validators.required])
      this.taskForm.controls['purchaseReminders'].clearValidators()
    }
    this.taskForm.controls['purchaseReminders'].updateValueAndValidity()
    this.taskForm.controls['taskDuration'].updateValueAndValidity()
  }

  filterFile(event) {
    console.log(event)
    this.fileListWithFilter = []
    this.categoryItem = [];
    if (event != 'all') {
      if (!event.categoryName) {
        this.fileList.forEach(file => {
          if (file.parentFolder == (this.project_id + '_' + event.masterId)) {
            console.log(file.parentFolder, this.project_id + '_' + event.masterId)
            console.log(file)

            this.fileListWithFilter.push(file);
          }
        });
        this.categoryItem = undefined;
      }
      else {

        this.categoryItem = this.docList.categoryItem;
        this.categoryItem = this.categoryItem.filter(cat => cat.id == event.id);
      }

    }
    else {
      this.categoryItem = undefined
      this.fileListWithFilter = this.fileList;
    }
    console.log(this.categoryItem)
    console.log(this.fileListWithFilter)
  }

  viewTemplate(type) {
    let team = [...this.project.contractorsTeam, ...this.project.suppliersTeam, ...this.project.team]
    /* this.commonServices.viewTemplate(team, this.taskForm.value, type, this.project, false, this.company) */
  }

  setPosition() {
    let taskList;
    if (this.selectedTask == "PARALLEL_TASK") {
      taskList = this.task.parallelTaskList.filter(singleTask => singleTask.stage.id == this.selectedStage.id && singleTask.deleteFlag != 'Y');
    }
    else {
      if (!this.task.taskList) {
        this.task.taskList = new Array<TaskList>();
      }
      taskList = this.task.taskList.filter(singleTask => singleTask.stage.id == this.selectedStage.id && singleTask.deleteFlag != 'Y');

    }
    this.taskForm.get('position').setValue(taskList.length + 1);
  }

  selectedTemplateType(event) {

    if (event.length == 1) {
      this.actionList = event;
    }
    else {
      let email = event.filter(template => template.template_type == "EMAIL")
      let actionListIndex = this.actionList.findIndex(selectedTemplate => selectedTemplate && selectedTemplate.template_type == "EMAIL")
      if (actionListIndex != -1) {
        if (email.filter(singleEmail => singleEmail.id != this.actionList[actionListIndex].id).length > 0) {
          this.actionList[actionListIndex] = email.filter(singleEmail => singleEmail.id != this.actionList[actionListIndex].id)[0]
        }
      }
      else {
        if (email[0]) {
          this.actionList.push(email[0])
        }
      }


      let sms = event.filter(template => template.template_type == "SMS");
      let actionListSMSIndex = this.actionList.findIndex(selectedTemplate => selectedTemplate && selectedTemplate.template_type == "SMS");
      if (actionListSMSIndex != -1) {
        if (sms.filter(singleSMS => singleSMS.id != this.actionList[actionListSMSIndex].id).length > 0) {
          this.actionList[actionListSMSIndex] = sms.filter(singleSMS => singleSMS.id != this.actionList[actionListSMSIndex].id)[0]
        }
      }
      else {
        if (sms[0]) {
          this.actionList.push(sms[0])
        }

      }
    }
    this.taskForm.get('selectedTemplate').setValue(this.actionList)

  }

  checkType(type) {
    let index = this.actionList.findIndex(template => template.template_type == type)
    if (index == -1) {
      return false
    }
    return true
  }

  selectDocItem(val) {
    let categoryId;
    this.docList.categoryItem.forEach(resp => {
      let index = resp.itemList.findIndex(item => item.id == val.id)
      if (index != -1) {
        categoryId = resp.id;
      }
    });

    this.taskForm.get('categoryItemId').setValue(categoryId);
    this.taskForm.get('itemId').setValue(val.id);
  }

}
