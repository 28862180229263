import { FilesItem } from "./file";

export class Budget {
    id: string;
    project_id: string;
    company_id: string;

    budgetList: Array<BudgetList>;
    category: Array<BudgetCategory>;

    updatedAt: string;
    deleteFlag: string;
    createdBy: string;
    updatedBy: string;

    estimatedTotal: number;
    actualTotal: number;
    differenceTotal: number;
    gst: number;
}

export class BudgetList {
    id: string;
    position: number;
    taskDescription: string;
    /* category: string; */
    estimated: number;
    actual: number;
    difference: number;
    notes: string;
    attachment: FilesItem;
    status: any;
    billStatus: any;
    UOM: any;
    total: any;
    gst: any;
    qty: any;
    unitCost: any;
    suppliers?: any;
    InvoiceID?: any;
    invoiceDetails: any;
    invoiceStatus?: any;
    statusColor?: any;
    taxIncluded?: boolean = true;
    AttachmentID?: boolean = true;
}

export class BudgetCategory {
    id: string;
    categoryName: string;
    budgetList: Array<BudgetList>;
}

export class BUDGET_LIST {
    id: string;
    BUILDER: Budget;
    budgetType: string;
}




/* Quotation */
export class QuatationBudget {
    id: string;
    project_id: string;
    company_id: string;
    quotationCategory: Array<QuatationCategory> = [];
    updatedAt: string;
    deleteFlag: string;
    createdBy: string;
    updatedBy: string;
    range: Array<RangePackageItem> = [];
    package: Array<RangePackageItem> = [];
    selectedRange: RangePackageItem;
    selectedPackage: RangePackageItem;
    floorPlan: Array<FilesItem> = [];
    facade: Array<FilesItem> = [];
    selectedFloorPlan?: FilesItem;
    selectedFacade?: FilesItem;
    quotationType = 'BUILDER';
}

export class QuatationCategory {
    id: string;
    quotationCategoryName: string;
    quotationSubCategoryList: Array<QuatationSubCategory> = [];
}

export class QuatationSubCategory {
    id: string;
    quotationCategoryId: string;
    item: string;
    notes: any;
    qty: string;
    unit: any;
    rate: string;
    total: any;
    range: Array<RangePackageItem> = [];
    package: Array<RangePackageItem> = [];
    costType;
    showPrice: boolean = true;
    printNumber?: number = 0;
    isFacadeOrPlan: boolean = false;
}

export class RangePackageItem {
    id: string;
    name: string;
    cost?: number;
}

