import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bt-add-update-single-name',
  templateUrl: './add-update-single-name.component.html',
  styleUrls: ['./add-update-single-name.component.scss'],
})
export class AddUpdateSingleNameComponent implements OnInit {
  itemValue;
  constructor(
    public dialogRef: MatDialogRef<AddUpdateSingleNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    this.itemValue = this.data.itemValue;
  }

}
