import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category } from 'src/app/model/company/inclusion';
import { CommonServices } from 'src/app/services/common-services/common-services.service';

@Component({
  selector: 'bt-inclusion-category-create',
  templateUrl: './inclusion-category-create.component.html',
  styleUrls: ['./inclusion-category-create.component.scss'],
})
export class InclusionCategoryCreateComponent implements OnInit {

  categoryName;
  categoryNote;

  constructor(
    public dialogRef: MatDialogRef<InclusionCategoryCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Category,
    public commonServices: CommonServices
  ) { }

  ngOnInit() {
    if (this.data) {
      this.categoryName = this.data.categoryName
      this.categoryNote = this.data.categoryNote
    }
    else {
      this.categoryName = undefined;
      this.categoryNote = undefined;
    }

  }

  passCategoryData() {

    if (this.categoryName) {
      let category: Category
      if (this.data) {
        category = this.data;
      }
      else {
        category = new Category()
        category.id = this.commonServices.getUniqueId();
      }

      category.categoryName = this.categoryName;
      category.categoryNote = this.categoryNote
      this.dialogRef.close(category)
    }
    else {
      this.commonServices.snackBarOpen('Category Name required', 'danger-snackbar')
    }

  }

}
