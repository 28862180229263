import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectStatus } from 'src/app/model/project/project';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { single, take } from 'rxjs/operators';

@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss'],
})
export class ProjectStatusComponent implements OnInit {
  MASTER_TEMPLATES = 'MASTER_TEMPLATES';
  projectStatus: ProjectStatus;
  documentForm: FormGroup;

  colorList = [
    {
      id: 0,
      color: 'rgb(100,116,139)',
      background: 'rgb(228 228 228)'
    },
    {
      id: 1,
      color: 'rgb(239,68,68)',
      background: 'rgb(254, 226, 226)'
    },
    {
      id: 2,
      color: 'rgb(249,115,22)',
      background: 'rgb(255, 237, 213)'
    },
    {
      id: 3,
      color: 'rgb(234,179,8)',
      background: 'rgb(254, 249, 195)'
    },
    {
      id: 4,
      color: 'rgb(34,197,94)',
      background: 'rgb(220, 252, 231)'
    },
    {
      id: 5,
      color: 'rgb(20,184,166)',
      background: 'rgb(204, 251, 241)'
    },
    {
      id: 6,
      color: 'rgb(59,130,246)',
      background: 'rgb(219, 234, 254)'
    },
    {
      id: 7,
      color: 'rgb(99,102,241)',
      background: 'rgb(224, 231, 255)'
    },
    {
      id: 8,
      color: 'rgb(168,85,247)',
      background: 'rgb(243, 232, 255)'
    },
    {
      id: 9,
      color: 'rgb(236,72,153)',
      background: 'rgb(252, 231, 243)'
    }
  ]
  tagColor = this.colorList[0];

  constructor(
    public dialogRef: MatDialogRef<ProjectStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public crudService: CrudService,
    public commonServices: CommonServices,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.documentForm = this.formBuilder.group({
      status_list: this.formBuilder.array([
        this.formBuilder.group({
          id: [this.commonServices.getUniqueId(), Validators.compose([Validators.required])],
          attribute_value: ['', Validators.compose([Validators.required])],
          attribute_key: [''],
          color: ['#fff', Validators.compose([Validators.required])],
          background: ['', Validators.compose([Validators.required])],
        })
      ])
    })


    this.crudService.collection$(this.MASTER_TEMPLATES, res => res.where('id', '==', 'PROJECT_STATUS')).pipe(take(1)).subscribe((resp: Array<ProjectStatus>) => {
      if (resp[0]) {
        this.projectStatus = resp[0]
        this.projectStatus.status_list.forEach((res, keyIndex) => {
          if (keyIndex != 0) {
            this.addStatus();

          }
          this.documentForm.patchValue(this.projectStatus);
        })
      }
    });

  }

  save() {
    if (this.documentForm.valid) {
      if (!this.projectStatus) {
        this.projectStatus = new ProjectStatus();
        this.projectStatus.status_list = this.documentForm.value.status_list
        this.setAttr();
        this.crudService.addWithCollName(this.MASTER_TEMPLATES, this.projectStatus, this.projectStatus.id).then(re => {
          this.commonServices.snackBarOpen('Status created');
        })
      } else {
        this.projectStatus.status_list = this.documentForm.value.status_list;
        this.setAttr();
        this.crudService.updateWithCollNamer(this.MASTER_TEMPLATES, this.projectStatus, this.projectStatus.id).then(re => {
          this.commonServices.snackBarOpen('Status updated');
        })
      }

    }
  }

  setAttr() {
    this.projectStatus.status_list.forEach(singleProject => {
      if (!singleProject.attribute_key) {
        singleProject.attribute_key = singleProject.attribute_value.toUpperCase().replace(/ /g, "")
      }
    })
  }

  addStatus() {
    let servicesArray = this.documentForm.controls.status_list as FormArray;
    let arraylen = servicesArray.length;
    let newStatus = this.formBuilder.group({
      id: [this.commonServices.getUniqueId(), Validators.compose([Validators.required])],
      attribute_value: ['', Validators.compose([Validators.required])],
      attribute_key: [''],
      color: ['#fff', Validators.compose([Validators.required])],
      background: ['', Validators.compose([Validators.required])],
    })
    servicesArray.insert(arraylen, newStatus)
  }

  selectTagColor(color, projectStatus) {
    projectStatus.get('background').setValue(color.background);
    projectStatus.get('color').setValue(color.color);
  }



}
