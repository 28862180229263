import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bt-add-category-model',
  templateUrl: './add-category-model.component.html',
  styleUrls: ['./add-category-model.component.scss'],
})
export class AddCategoryModelComponent implements OnInit {

  itemName: string;

  constructor(
    public dialogRef: MatDialogRef<AddCategoryModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() { }

  setData() {
    if (this.itemName) {
      this.dialogRef.close(this.itemName);
    }
  }

}
