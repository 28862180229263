import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Category } from 'src/app/model/company/inclusion';
import * as _ from 'lodash';
import { EventService } from 'src/app/services/event/event.service';
/* import { InclusionStepperPreviewComponent } from '../inclusion-stepper-preview/inclusion-stepper-preview.component'; */
import { ComponentRef } from '@angular/core';
import { InclusionStepperComponent } from '../inclusion-stepper/inclusion-stepper.component';
import { paymentTypeList } from 'src/app/model/project/payments-contract';

@Component({
  selector: 'bt-inclusion-category-list',
  templateUrl: './inclusion-category-list.component.html',
  styleUrls: ['./inclusion-category-list.component.scss'],
})
export class InclusionCategoryListComponent implements OnInit, OnChanges {

  @Input() category;
  @Output() editCategory: EventEmitter<any> = new EventEmitter();
  @Output() updateCategory: EventEmitter<any> = new EventEmitter();
  @Input() selectedCategoryId: string;
  @Input() categoryIndex: string;
  @Input() selectedInclusion: any;
  @Input() filterByStatusValue;
  dialogRef;

  constructor(
    public dialog: MatDialog,
    private eventService: EventService,
  ) { }

  ngOnInit() {
    if (this.selectedCategoryId) {
      if (this.selectedInclusion) {
        let index = this.selectedInclusion.categoryItem[this.categoryIndex].subCategory.findIndex(sub => sub.id == this.selectedCategoryId)
        if (index != -1) {
          this.category = this.selectedInclusion.categoryItem[this.categoryIndex];
          this.openInclusionPreview();
        }
      }
      else {
        this.openInclusionPreview();
      }
    }
  }

  ngOnChanges() {

  }

  openInclusionView(sub?) {
    const dialogRef = this.dialog.open(InclusionStepperComponent, {
      width: '100vw',
      panelClass: 'stepper_full_popup',
      data: {
        category: this.category,
        subCategory: sub,
        selectedInclusion: this.selectedInclusion,
        categoryIndex: this.categoryIndex,
      }
    });
    let onSave = dialogRef.componentInstance.onSave.subscribe(result => {
      if (result) {
        this.category = result;
        console.log(result)
        this.updateCategoryEv(result)
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.category = result;
        this.updateCategoryEv(this.category)
      }
      onSave.unsubscribe();
    });
  }

  openInclusionPreview() {
    /* if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(InclusionStepperPreviewComponent, {
        width: '100vw',
        panelClass: 'stepper_full_popup',
        disableClose: true,
        data: {
          category: this.category,
          selectedCategoryId: this.selectedCategoryId,
        }
      });
    } */
  }

  editCategoryOpen(category: Category) {
    this.editCategory.emit(category)
  }

  updateCategoryEv(result) {
    console.log(result)
    /* this.updateCategory.emit(result) */
    this.eventService.publish('update_inclusion', result);
  }

}
