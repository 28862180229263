import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bt-manage-inventory-view',
  templateUrl: './manage-inventory-view.component.html',
  styleUrls: ['./manage-inventory-view.component.scss'],
})
export class ManageInventoryViewComponent implements OnInit {

  @Input() inventoryData;
  constructor() { }

  ngOnInit() { }

}
