import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertController, LoadingController } from '@ionic/angular';
import { CrudService } from '../crud-service/crud-service.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Storage } from '@ionic/storage-angular';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AllCollection } from 'src/app/model/company/role-access';
import { PROGRESS_PAYMENTS } from 'src/app/model/project/payments-contract';
import { Budget } from 'src/app/model/project/budget';
import { FILETAGS } from 'src/app/model/project/file-tag';
import { DOCLIST } from 'src/app/model/doc/doc-list';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import * as FileSaver from 'file-saver';
import { FilesItem } from 'src/app/model/project/file';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';



@Injectable({
  providedIn: 'root'
})
export class CommonServices {

  loading;


  master_template: BehaviorSubject<any>;
  PROGRESS_PAYMENTS: PROGRESS_PAYMENTS;
  BUDGET: Budget;
  FILETAGS: FILETAGS;
  DOCLIST: DOCLIST;

  baseURL = "https://calm-ravine-12528.herokuapp.com";
  FCMLINK = "https://fcm.googleapis.com/fcm/send";
  /* baseURL = "http://localhost:5000"; */


  @ViewChild('inputFile') inputFile: ElementRef;
  isExcelFile: boolean;


  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private snackBar: MatSnackBar,
    private crudService: CrudService,
    private storage: Storage,
    private httpClient: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
  ) {



    this.crudService.collection$('MASTER_TEMPLATES').subscribe((resp: any) => {
      this.master_template = new BehaviorSubject(resp[0]);
      this.PROGRESS_PAYMENTS = resp.filter(singleItem => singleItem.id == "PROGRESS_PAYMENTS")[0];
      this.DOCLIST = resp.filter(singleItem => singleItem.id == "DOCLIST")[0];
      this.FILETAGS = resp.filter(singleItem => singleItem.id == "FILETAGS")[0];
      this.BUDGET = resp.filter(singleItem => singleItem.id == "BUDGET")[0];
    })
  }



  /* Compare Obj for select */
  compareFn(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  async alertPopup(header, message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      buttons: ['okay']
    });

    await alert.present();
  }


  /* Genrate Unique Id */
  getUniqueId(parts: number = 4): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }


  /* Loading */
  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingController.create({
        message: 'Please wait...',
      });
      await this.loading.present();
    }
  }

  dismissLoading() {
    if (this.loading) {
      this.loading.dismiss();
    }
  }

  /* Tost Popup */
  snackBarOpen(text, classNme?) {
    this.snackBar.open(text, '', { duration: 2000, panelClass: classNme })
  }

  importExcle(evt): Promise<any> {
    let data, header;
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);
    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }
    if (this.isExcelFile) {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        data = XLSX.utils.sheet_to_json(ws);
      };

      reader.readAsBinaryString(target.files[0]);

      return new Promise((resolve, reject) => {
        reader.onloadend = (e) => {
          let keys = Object.keys(data[0]);
          /* this.dataSheet.next(data) */
          resolve({ keys, data });
        }
      })

    } else {
      this.inputFile.nativeElement.value = '';
    }
  }

  convert(data, col, name) {

    const doc = new jsPDF()
    var rows = data;
    autoTable(doc, {
      head: [
        col
      ],
      body: rows,
    })
    doc.save(name + '.pdf');
  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  formData(file, path?): FormData {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path);
    return formData;
  }

  public uploadFile(resp, parentFolder, tagItem): FilesItem {
    let filesItem = new FilesItem();
    filesItem.id = resp[0].id;
    filesItem.fileName = resp[0].name;
    filesItem.fileType = resp[0].file.mimeType;
    filesItem.size = resp[0].size;
    filesItem.lastModifiedDateTime = resp[0].lastModifiedDateTime;
    filesItem.thumbnail = (resp[1].value[0]) ? resp[1].value[0].large : '';
    filesItem.parentFolder = parentFolder;
    if (tagItem) {
      filesItem.tagItem.push(tagItem)
    }

    return filesItem;
  }

  async deleteConfirmation() {
    const alert = await this.alertController.create({
      header: 'Delete',
      message: 'Are you sure you want to delete ?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //console.log('Confirm Cancel');
          }
        }, {
          text: 'Delete',
          role: 'delete',
          cssClass: 'danger',
          handler: (res) => {
            //console.log(res);
          }
        }
      ]
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  public getJSON(): Observable<any> {
    return this.httpClient.get("single_storey.json");
  }

  generatePassword() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

}
