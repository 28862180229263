import { SuppliersContractors } from "../contractors-suppliers/suppliers-contractors";
import { Tag } from "../contractors-suppliers/tag";
import { User } from "../user/user";

export class Project {

    id: string;
    company_id: string;
    project_name: string;
    project_location: string;
    project_section: string;
    project_block: string;
    project_suburb: string;
    project_description: string;
    project_expected_start_date: string;
    project_expected_end_date: string;
    project_type: any;
    project_client: any;
    project_status: any;
    project_task_status: any;
    project_current_running_task: any;
    project_current_running_stage: any;

    budget_id: string;
    payment_id: string;

    projectDetail: ProjectDetail;

    updatedAt: string;
    deleteFlag: string;
    createdBy: string;
    updatedBy: string;

    team: Array<User> = [];
    contractorsTeam: Array<SuppliersContractors> = [];
    suppliersTeam: Array<SuppliersContractors> = [];

    archive_id: string;
}


export class ProjectDetail {

    /* Amount Total */
    contract_total: number = 0;
    variation_total: number = 0;
    estimated_total: number = 0;
    actual_total: number = 0;
    difference_total: number = 0;
    invoiced_total: number = 0;

    /* Task Total */
    task_total: number = 0;
    completed_task_total: number = 0;
    client_task_total: number = 0;
    client_completed_task_total: number = 0;

    files_total: number = 0;


    sms_total: number;
    email_total: number;
    whatsapp_total: number;


}

export class ProjectStatus {
    id: string = 'PROJECT_STATUS';
    status_list: Array<ProjectStatusItem> = [];
    updatedAt: string;
    deleteFlag: string;
    createdBy: string;
    updatedBy: string;

}

export class ProjectStatusItem {
    id: string;
    attribute_value: string;
    attribute_key: string;
    color: string;
    background: string;
}