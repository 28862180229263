// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAJGiXh2oEEK05K1q60siEtrjbPoik9--g",
    authDomain: "build-task-staging.firebaseapp.com",
    projectId: "build-task-staging",
    storageBucket: "build-task-staging.appspot.com",
    messagingSenderId: "362075492491",
    appId: "1:362075492491:web:1938ed0ea42539502c448e",
    measurementId: "G-HR47TM5431"
  },
  API_URL: 'https://buildmacro.onrender.com',
  //API_URL: 'http://localhost:5001',

  /* production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDGv24pjyluQ9wYUHDach22WWbIXuyGI3g",
    authDomain: "build-task.firebaseapp.com",
    projectId: "build-task",
    storageBucket: "build-task.appspot.com",
    messagingSenderId: "653365021167",
    appId: "1:653365021167:web:4da39aa099644318704e1a",
    measurementId: "G-ZKNRQZTV0B"
  },
  API_URL: 'https://buildmacro.herokuapp.com', */
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
