import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGardService implements CanActivate {

  constructor(
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private menuController: MenuController,
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    return this.angularFireAuth.authState.pipe(
      take(1),
      map(authState => {
        if (authState) {
          this.menuController.enable(true)
          return true;
        }
        this.menuController.enable(false)
        this.router.navigate(['login']);
        return false;
      })
    )
  }

}
