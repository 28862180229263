import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { attributeList } from 'src/app/attribute-list/attribute-list';
import { Project } from 'src/app/model/project/project';
import { StageList, Task, TaskList } from 'src/app/model/project/task';
import { CommonServices } from 'src/app/services/common-services/common-services.service';
import { CrudService } from 'src/app/services/crud-service/crud-service.service';
/* import { AppDownloadComponent } from '../app-download/app-download.component'; */

@Component({
  selector: 'bt-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss'],
})
export class AddTaskComponent implements OnInit {

  taskListCollection: string = "taskList";
  taskForm;
  public filteredList1;
  @Input() project: Project;
  @Input() tradeTypeList;
  public variables = [];

  @Input() fileList;
  fileListWithFilter;
  @Input() fileTags;
  @Input() docList;
  @Input() templateVariable;
  @Input() company;
  @Input() allTask: Task;
  @Input() selectedTask: Task;

  SEQUENCE_TASK_LIST = [];
  filteredList2;
  actionList = [];
  statusColor = attributeList.colorList[0];
  colorList = attributeList.colorList;
  categoryItem;

  @Input() selectedStage: StageList;

  @Output() closeTassTask = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    public commonServices: CommonServices,
    public crudService: CrudService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {

    this.fileListWithFilter = this.fileList;

    this.variables = [...this.tradeTypeList.contractors, ...this.tradeTypeList.suppliers];
    this.filteredList1 = [...this.tradeTypeList.contractors, ...this.tradeTypeList.suppliers];
    this.filteredList2 = this.templateVariable;


    this.taskForm = this.formBuilder.group({
      id: [this.commonServices.getUniqueId()],
      type: ['SEQUENCE_TASK', Validators.compose([Validators.required])],
      title: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],
      actionBy: ['', Validators.compose([Validators.required])],
      action: [false, Validators.compose([Validators.required])],
      selectedTemplate: ['', Validators.compose([Validators.required])],
      taskDuration: ['', Validators.compose([Validators.required])],
      files: [''],
      dependency: [false],
      startDate: [''],
      endDate: [''],
      purchaseOrder: [false],
      purchaseItem: [false],
      parentTask: [''],
      deleteFlag: ['N'],
      purchaseReminders: [0],
      task_status: [attributeList.task_status[0]],
      position: [1],
      createdDate: [new Date()],
      categoryItemId: [''],
      itemId: [''],
      displayColor: [this.statusColor]
    });

    console.log(this.selectedStage)
    /* this.SEQUENCE_TASK_LIST = this.selectedStage.taskList.filter(singleTask => singleTask.type == 'SEQUENCE_TASK'); */
    if (this.selectedTask) {
      this.taskForm.patchValue(this.selectedTask);
    }

  }

  filterFile(event) {
    console.log(event)
    this.fileListWithFilter = []
    this.categoryItem = [];
    if (event != 'all') {
      if (!event.categoryName) {
        this.fileList.forEach(file => {
          if (file.parentFolder == (this.project.id + '_' + event.masterId)) {
            console.log(file.parentFolder, this.project.id + '_' + event.masterId)
            console.log(file)

            this.fileListWithFilter.push(file);
          }
        });
        this.categoryItem = undefined;
      }
      else {

        this.categoryItem = this.docList.categoryItem;
        this.categoryItem = this.categoryItem.filter(cat => cat.id == event.id);
      }

    }
    else {
      this.categoryItem = undefined
      this.fileListWithFilter = this.fileList;
    }
    console.log(this.categoryItem)
    console.log(this.fileListWithFilter)
  }


  checkType(type) {
    let index = this.actionList.findIndex(template => template.template_type == type)
    if (index == -1) {
      return false
    }
    return true
  }


  selectedTemplateType(event) {

    if (event.length == 1) {
      this.actionList = event;
    }
    else {
      let email = event.filter(template => template.template_type == "EMAIL")
      let actionListIndex = this.actionList.findIndex(selectedTemplate => selectedTemplate && selectedTemplate.template_type == "EMAIL")
      if (actionListIndex != -1) {
        if (email.filter(singleEmail => singleEmail.id != this.actionList[actionListIndex].id).length > 0) {
          this.actionList[actionListIndex] = email.filter(singleEmail => singleEmail.id != this.actionList[actionListIndex].id)[0]
        }
      }
      else {
        if (email[0]) {
          this.actionList.push(email[0])
        }
      }


      let sms = event.filter(template => template.template_type == "SMS");
      if (this.company.tokenList.length == 0) {
        this.taskForm.get('selectedTemplate').setValue(event.filter(template => template.template_type != "SMS"))
        /* const dialogRef = this.dialog.open(AppDownloadComponent, {
          width: '550px',
          data: {}
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
        }); */

      }
      else {
        let actionListSMSIndex = this.actionList.findIndex(selectedTemplate => selectedTemplate && selectedTemplate.template_type == "SMS");
        if (actionListSMSIndex != -1) {
          if (sms.filter(singleSMS => singleSMS.id != this.actionList[actionListSMSIndex].id).length > 0) {
            this.actionList[actionListSMSIndex] = sms.filter(singleSMS => singleSMS.id != this.actionList[actionListSMSIndex].id)[0]
          }
        }
        else {
          if (sms[0]) {
            this.actionList.push(sms[0])
          }

        }
      }
    }
    this.taskForm.get('selectedTemplate').setValue(this.actionList)

  }


  taskCreate() {

    console.log(this.taskForm.value)

    this.setTaskType();
    this.setposition()
    if (this.taskForm.valid && this.selectedStage) {
      if (this.selectedStage.taskList.length == 0) {
        this.selectedStage.taskList.push(this.taskForm.value)
      }
      else {
        let index = this.selectedStage.taskList.findIndex(singleTask => singleTask.id == this.taskForm.value.id)

        if (index != -1) {
          this.selectedStage.taskList[index] = this.taskForm.value;
        }
        else {
          this.selectedStage.taskList.push(this.taskForm.value)
        }
      }


      let index = this.allTask.stageList.findIndex(singleStage => singleStage.id == this.selectedStage.id);
      this.allTask.stageList[index].taskList = this.selectedStage.taskList;
      this.crudService.updateWithCollNamer('MASTER_TEMPLATES', this.allTask, this.allTask.id).then(res => {
        this.closeTassTask.emit()
      }).catch(er => {
        console.log(er)
      })
    }
    else {
      for (let el in this.taskForm.controls) {
        if (this.taskForm.controls[el].errors) {
          console.log(el)
        }
      }
    }

  }

  setTaskType() {
    if (this.taskForm.value.dependency == true || this.taskForm.value.parentTask) {
      this.taskForm.get('type').setValue('PARALLEL_TASK');
    }
    else {
      this.taskForm.get('type').setValue('SEQUENCE_TASK');
    }
  }

  setposition() {
    /* let selectedStage = this.taskForm.get('stage').value; */

    let position = 0;
    if (this.taskForm.get('type').value == 'PARALLEL_TASK') {
      let selectedParentTask = this.selectedStage.taskList.filter(singleTask => singleTask.id == this.taskForm.value.parentTask)[0];
      let selectedStageTask = this.selectedStage.taskList.filter(singleTask => singleTask.parentTask == this.taskForm.value.parentTask);
      position = Number(selectedParentTask.position + '.' + (selectedStageTask.length + 1));

    }
    else {
      let selectedStageTask = this.selectedStage.taskList.filter(singleTask => singleTask.type == 'SEQUENCE_TASK');
      position = selectedStageTask.length + 1
    }

    this.taskForm.get('position').setValue(position);

  }


  checkTeam(teamType) {
    if (!teamType.uid) {

      if (!this.project.suppliersTeam) {
        this.project.suppliersTeam = [];
      }
      if (!this.project.contractorsTeam) {
        this.project.contractorsTeam = [];
      }
      if (!this.project.team) {
        this.project.team = [];
      }

      let allTeam = [...this.project.suppliersTeam, ...this.project.contractorsTeam];
      let teamIndex = allTeam.findIndex(teamItem => teamItem.default_trade_id == teamType.id);
      if (teamIndex == -1) {
        this.commonServices.alertPopup('Error', teamType.tag_name + ' team is missing');
        this.taskForm.get('actionBy').setValue('');
      }
    }

  }

  checkDependency(event) {
    if (event.detail.checked) {
      this.taskForm.controls['parentTask'].setValidators([Validators.required]);
    }
    else {
      this.taskForm.controls['parentTask'].clearValidators();
    }
    this.taskForm.controls['parentTask'].updateValueAndValidity();
  }

  fileValidate(event) {
    if (event.detail.checked) {
      this.taskForm.controls['files'].setValidators([Validators.required])
    }
    else {
      this.taskForm.controls['files'].clearValidators()
    }
    this.taskForm.controls['files'].updateValueAndValidity()
  }


  selectDocItem(val) {
    let categoryId;
    this.docList.categoryItem.forEach(resp => {
      let index = resp.itemList.findIndex(item => item.id == val.id)
      if (index != -1) {
        categoryId = resp.id;
      }
    });

    console.log(categoryId)
    console.log(val.id)

    this.taskForm.get('categoryItemId').setValue(categoryId);
    this.taskForm.get('itemId').setValue(val.id);
  }

  clearCategoryItem() {
    this.taskForm.get('categoryItemId').setValue('');
    this.taskForm.get('itemId').setValue('');
  }


  selectStageSequenceTask(value: StageList) {
    /* console.log(value)
    this.selectedStage = value; */
    if (!value.taskList) {
      value.taskList = [];
    }
    this.SEQUENCE_TASK_LIST = value.taskList.filter(singleTask => singleTask.type == 'SEQUENCE_TASK');
  }

  viewTemplate(type) {
    let team = [...this.project.contractorsTeam, ...this.project.suppliersTeam, ...this.project.team]
    /* this.commonServices.viewTemplate(team, this.taskForm.value, type, this.project, false, this.company) */
  }

}
