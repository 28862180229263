import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { attributeList } from 'src/app/attribute-list/attribute-list';
import { CommonServices } from 'src/app/services/common-services/common-services.service';

@Component({
  selector: 'bt-budget-line-item',
  templateUrl: './budget-line-item.component.html',
  styleUrls: ['./budget-line-item.component.scss'],
})
export class BudgetLineItemComponent implements OnInit {

  @Input() suCategory;
  @Output() delete = new EventEmitter();
  unitList = attributeList.unitList;
  costTypeList = attributeList.costTypeList;
  costType;
  constructor(
    private formBuilder: FormBuilder,
    private commonServices: CommonServices,
  ) { }

  ngOnInit() {

    if (this.suCategory.get('costType').value) {
      this.costType = this.suCategory.get('costType').value.attribute_key
    }

    this.suCategory.valueChanges.subscribe(x => {
      this.suCategory.get('total').setValue(x.qty * x.rate, { onlySelf: true, emitEvent: false });
      /* if (this.group) {
        this.returnValue.emit(x)
      } */
    })

  }

  deleteItem() {
    this.delete.emit();
  }

  changeCostType(val) {
    this.costType = val.attribute_key;
    if (this.costType == 'INCLUDED' || this.costType == 'BY_CUSTOMER') {
      console.log(val)
      console.log(this.costType)
      this.suCategory.get('rate').setValue(0)
      this.suCategory.get('qty').setValue(0)
    }


  }



}
