import { FilesItem } from "../project/file";
import { SingleNote } from "../project/note";

export class Inclusions {
    company_id: string;
    createdAt: string;
    createdBy: string;
    deleteFlag: string;
    id: string;
    inclusionName: string;
    project_id: string;
    templateName: string;
    updatedAt: string;
    updatedBy: string;
    categoryItem: Array<Category>;

    documentTitle: string;
    disclaimer: string;
    inclusionType?: string;
}


export class Category {
    id: string;
    categoryName: string;
    categoryNote: string;
    subCategory: Array<SubCategory> = [];
}
export class SubCategory {
    id: string;
    amount: number;
    clientSelection: string;
    description: string;
    name: string;
    attachedPath: Array<FilesItem>;
    noteList: Array<SingleNote> = [];
    finalize: boolean = false;
    selectionData: Array<any> = [];
}


export class clientSelection {
    id: string
    title_question: string;
    ans_type: string;
    answer_short_text: string;
    multipleChoice: Array<any> = [];
    selectionGuide: string;
    attachment: Array<FilesItem> = [];
}

export class MASTER_INCLUSION{
    id:string = 'INCLUSION';
    inclusions:Array<Inclusions> = []
}